.Pconcept_container {
  overflow: hidden;
  height: 800px;
  background: #f1f2f4;
  .Pconcept_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    .Pconcept_txt_bx {
      overflow: hidden;
      float: left;
      margin-top: 100px;
      .Pconcept_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pconcept_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
    .Pconcept_image {
      overflow: hidden;
      float: left;
      margin: 137px 0 0 136px;
      width: 576px;
      height: 476px;
      > img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Pconcept_container {
    padding-bottom: 30px;
    height: fit-content;
    .Pconcept_cont {
      width: 100%;
      .Pconcept_image {
        width: 100%;
        height: 100%;
        margin-left: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
