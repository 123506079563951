.serverkit_session6 {
    color: white;
    background-color: #6d42ff;
    padding: 120px 0 118px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .sk_title {
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            margin-bottom: 28px;
            span {
                color: #111;
            }
        }
        .sk_des{
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            margin-bottom: 60px;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
                .sk_des_mobile {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 60px;
                    display: none;
                    @media screen and (max-width: 767px) {
                            display: block;
                        }
                }


        @media screen and (max-width:1400px){
           .sk_image{
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
           } 
        }
    }

    @media screen and (max-width:768px){
        padding: 60px 0 50px 0px;

        .sk_main{
            width: 90%;
            margin: 0 auto;
            .sk_title{
                font-size: 25px;
            }

            .sk_des{
                font-size: 16px;
            }
        }
    }
}
