.Gvaluablecontainer {
  position: relative;
  .premium_mobile_inner {
    position: relative;
    margin-top: 236px !important;
    .Gvalue_title {
      font-size: 30px;
      font-weight: 900;
    }
    .Gvalue_text {
      margin-top: 35px;
      font-size: 14px;
      line-height: 2;
    }
    .value_list01 {
      list-style: none;
      display: inline-block;
      position: relative;
      width: 262px;
      height: 466px;
      vertical-align: top;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
      > img {
        width: 100%;
      }
    }
    .value_list02 {
      display: inline-block;
      position: relative;
      width: 262px;
      height: 466px;
      margin: 100px 0 0 50px;
      vertical-align: top;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
      > img {
        width: 100%;
      }
    }
    .value_list03 {
      display: inline-block;
      position: relative;
      width: 262px;
      height: 466px;
      margin: 190px 0 0 50px;
      vertical-align: top;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
      > video {
        width: 100%;
      }
    }
    .value_list04 {
      display: inline-block;
      position: relative;
      width: 262px;
      height: 466px;
      margin: 280px 0 0 50px;
      vertical-align: top;
      box-shadow: 60px 60px 50px rgba(0, 0, 0, 0.1);
      > img {
        width: 100%;
      }
    }
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
