.ForthPageContainer {
    max-width: 1630px;
    margin: auto;
    .ForthPageMain {
        padding: 80px 50px 0;
        max-width: 1620px;
        width: 100%;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: Montserrat;
    }
    .ForthPageLeftBox {
        display: flex;
        flex-direction: row;
        font-size: 15px;
        font-weight: 600;
        padding-top: 48px;
        padding-right: 120px;
    }
    .LeftText {
        margin-right: 9px;
        color: #222222;
    }
    .LeftArrow{
        color: #222222;
    }
    .FortAllPageBox {
        padding-left: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ForthPageRightBox {
        display: flex;
        flex-direction: column;
        max-width: 1120px;
        width: 100%;
        margin: auto;
    }
    .RightContentsBox {
        width: 60vw;
        display: flex;
        flex-direction: column;
        color: #1a1a19;
        border-bottom: 4px solid #1a1a19;
        margin-top: 35px;

        &:nth-child(1) {
            margin-top: initial;
        }
    }
    .RightTopBox {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .arrow {
            width: 3vw;
        }
    }
    .RightTitle {
        font-size: 3.5vw;
        font-weight: 600;
        line-height: 1;
    }
    .RightBottomText {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.3;
        padding-left: 7px;
        text-align: left;
        margin-bottom: 28px;
    }
}
// .fp-tableCell{
//     vertical-align: initial !important;
// }
@media screen and (min-width: 1920px) {
    .RightTopBox {
        .arrow {
            width: 60px !important;
        }
    }
    .RightTitle {
        font-size: 60px !important;
    }
    .RightContentsBox{
        width: 100% !important;
    }
}
@media all and (max-width: 1024px) {
    .ForthPageLeftBox {
        padding-right: 20px !important;
    }
    .RightBottomText {
        font-size: 1.2vw !important;
    }
}

//Tablet | Mobile 분기점
// @media all and (max-width: 1024px) {
//     .ForthPageContainer {
//         max-width: 767px;
//         margin: auto;
//         .ForthPageMain {
//             padding-top: 50px;
//             max-width: 767px;
//             width: 100%;
//             margin-right: auto;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             font-family: Montserrat;
//         }
//         .ForthPageLeftBox {
//             display: flex;
//             flex-direction: row;
//             font-size: 6px;
//             font-weight: 600;
//             padding-right: 25px;
//             padding-left: 18px;
//             display: none;
//         }
//         .LeftText {
//             margin-right: 9px;
//             padding-bottom: 48px;
//         }
//         .FortAllPageBox {
//             padding-left: 100px;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//         }
//         .ForthPageRightBox {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             max-width: 100%;
//             width: 100%;
//         }
//         .RightContentsBox {
//             // width: 260px;
//             display: flex;
//             flex-direction: column;
//             color: #1a1a19;
//             border-bottom: 4px solid #1a1a19;
//             margin-top: 35px;
//             &:nth-child(1) {
//                 margin-top: initial;
//             }
//         }
//         .RightTopBox {
//             width: 100%;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             margin-bottom: 8px;
//         }
//         .RightTitle {
//             font-size: 30px;
//             font-weight: 600;
//             line-height: 1;
//         }
//         .RightBottomText {
//             font-size: 6px;
//             font-weight: 500;
//             line-height: 1.5;
//             text-align: left;
//             margin-bottom: 8px;
//         }
//         .arrow {
//             width: 30px;
//             height: 26px;
//         }
//     }
// }

// @media all and (max-width: 767px) {
//     .ForthPageContainer {
//         max-width: 767px;
//         margin: auto;
//         .ForthPageMain {
//             padding-top: 50px;
//             max-width: 767px;
//             width: 100%;
//             margin-right: auto;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             font-family: Montserrat;
//         }
//         .ForthPageLeftBox {
//             display: flex;
//             flex-direction: row;
//             font-size: 6px;
//             font-weight: 600;
//             padding-right: 25px;
//             padding-left: 18px;
//             display: none;
//         }
//         .LeftText {
//             margin-right: 9px;
//             padding-bottom: 48px;
//         }
//         .FortAllPageBox {
//             padding-left: 100px;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             align-items: center;
//         }
//         .ForthPageRightBox {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             max-width: 100%;
//             width: 100%;
//         }
//         .RightContentsBox {
//             width: 260px;
//             display: flex;
//             flex-direction: column;
//             color: #1a1a19;
//             border-bottom: 4px solid #1a1a19;
//             margin-top: 35px;
//             &:nth-child(1) {
//                 margin-top: initial;
//             }
//         }
//         .RightTopBox {
//             width: 100%;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             margin-bottom: 8px;
//         }
//         .RightTitle {
//             font-size: 30px;
//             font-weight: 600;
//             line-height: 1;
//         }
//         .RightBottomText {
//             font-size: 6px;
//             font-weight: 500;
//             line-height: 1.5;
//             text-align: left;
//             margin-bottom: 8px;
//         }
//         .arrow {
//             width: 30px;
//             height: 26px;
//         }
//     }
// }
