.HeaderWhiteContainer{
  background-color: #ffffff;
  .HeaderWhite{
    display: flex;
    max-width: 1630px;
    width: 100%;
    margin: auto;
    transition: opacity 0.3s ease 0s;
    justify-content: space-between;
    user-select: none;
    z-index: 1;
    padding: 80px 30px 0;
  }
  .HeaderLogo {
    font-size: 30px;
    font-weight: bold;
    color: #222222;
    font-family: "Montserrat";
    line-height: 1;
  }
}
@media all and (max-width:1270px) {
  .HeaderWhiteContainer{
    .HeaderLogo{
      font-size: 29px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .HeaderWhiteContainer{
    .HeaderWhite{
      padding: 70px 50px 0;
      align-items: flex-start;
    }
  }
}

@media all and (max-width:767px) {
  .HeaderWhiteContainer{
    .HeaderWhite{
      padding: 30px 25px 0;
    }
    .HeaderLogo{
      font-size: 25px;
    }
  }
}

@media all and (max-width:400px) {
  .HeaderWhiteContainer{
    .HeaderLogo{
      font-size: 23px;
    }
  }
}

@media all and (max-width:350px) {
  .HeaderWhiteContainer{
    .HeaderLogo{
      font-size: 20px;
    }
  }
}
@media all and (max-width:300px) {
  .HeaderWhiteContainer{
    .HeaderLogo{
      font-size: 17px;
    }
  }
}
