.Ecouponcontainer {
  margin-top: 383px;
  padding: 403px 0 390px;
  background: #f0f0f0;
  .con_bx {
    width: 1200px;
    margin: 0 auto;
    .coupon_sub {
      overflow: hidden;
      margin-top: 33px;
      color: #808080;
      font-size: 16px;
      font-weight: 500;
      line-height: 38px;
    }
    .list {
      display: flex;
      margin-top: 147px;
      padding-left: 10px;
      font-size: 0;
      line-height: 0;
      .coupon_Wrap {
        display: inline-block;
        margin: 0 84px 0 0;
        text-align: center;
        vertical-align: top;
        > img {
          box-shadow: 20px 20px 40px rgba(34, 34, 34, 0.15);
          margin-bottom: 10px;
        }
        .frame {
        }
      }
    }
  }
}
