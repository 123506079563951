.Missioncontainer {
  overflow: hidden;
  .mission_top {
    overflow: hidden;
    max-width: 1226px;
    width: 100%;
    margin: 0 auto 163px;
    @media screen and (max-width: 480px) {
      margin-bottom: 50px;
      .honemW {
        font-size: 35px;
        letter-spacing: 15px;
        margin-left: 25px;
      }
      .clqdqB {
        font-size: 12px;
        margin-bottom: 50px;
      }
    }
    @media screen and (min-width: 481px) and (max-width: 978px) {
      #overflow_video {
        width: 500px;
      }
    }
  }
  .mission_ul {
    overflow: hidden;
    position: relative;
    left: 50%;
    width: 100%;
    margin: 163px 0 272px -604px;
    @media screen and (max-width: 480px) {
      width: 80%;
      left: 0;
      margin: 0;
      .mission_01,
      .mission_02,
      .mission_03 {
        height: 100px !important;
        .Top_number {
          top: 20%;
          left: 30px !important;
          font-size: 12px !important;
        }
        .Image_Title {
          font-size: 22px !important;
          top: 32% !important;
          left: 30px !important;
        }
        .Bottom_text {
          font-size: 12px !important;
          top: 40%;
          left: 80% !important;
        }
      }
    }
    @media screen and (min-width: 481px) and (max-width: 980px) {
      width: 80%;
      left: 0;
      margin: 0;
      .mission_01,
      .mission_02,
      .mission_03 {
        height: 200px !important;
        .Top_number {
          top: 20%;
          left: 30px !important;
          font-size: 12px !important;
        }
        .Image_Title {
          font-size: 22px !important;
          top: 32% !important;
          left: 30px !important;
        }
        .Bottom_text {
          font-size: 12px !important;
          top: 40%;
          left: 80% !important;
        }
      }
    }
    .mission_01 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission01.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
    .mission_02 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission02.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
    .mission_03 {
      list-style: none;
      background-image: url(../../../../../_asset/images/29TV/img_mission03.jpg);
      background-position: 0 50%;
      position: relative;
      height: 220px;
      background-repeat: no-repeat;
      background-size: cover;
      .Top_number {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        position: relative;
        top: 20%;
        left: 60px;
      }
      .Image_Title {
        font-size: 50px;
        color: #fff;
        position: relative;
        top: 50%;
        left: 60px;
      }
      .Bottom_text {
        font-size: 15px;
        color: #fff;
        position: relative;
        top: 40%;
        left: 70%;
      }
    }
  }
}
