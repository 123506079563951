.Pcolor_container {
  overflow: hidden;
  .Pcolor_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Pcolor_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Pcolor_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Pcolor_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Pcolor_cont_2 {
    overflow: hidden;
    height: 800px;
    background: #fafbfb;
    .Pcolor_img_Wrap {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 121px;
      > img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Pcolor_container {
    width: 100%;
    .Pcolor_cont_1 {
      width: 100%;
      padding: 0 15px;
      .Pcolor_txt_bx {
        width: 100%;
      }
    }
    .Pcolor_cont_2 {
      height: fit-content;
      padding-bottom: 150px;
      .Pcolor_img_Wrap {
        width: 100%;
      }
    }
  }
}
