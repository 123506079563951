.back-to-top {
  position: fixed;
  bottom: 200px;
  right: 100px;
  
  background-color: #fff;
  border-radius: 100%;

  z-index: 9999;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  @media screen and (max-width: 800px) {
    width: 30px;
    height: 30px;
    bottom: 100px;
    display: none;
  right: 25px;
    img{
      opacity: 0;
      z-index: 0;
      width: 30px;
      height: 30px;

    }
  }
}
