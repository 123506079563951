.Gcovercontainer {
  position: relative;
  height: 1080px;
  .top_logo {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .logo_Wrap {
      width: 268px;
      height: 98px;
      margin-top: -23px;
      > img {
        width: 100%;
        height: auto;
        vertical-align: top;
      }
    }
  }
  .GcoverImage {
    overflow: hidden;
    position: absolute;
    top: 222px;
    left: 50%;
    width: 353px;
    margin-left: 247px;
    border-radius: 40px;
    box-shadow: 60px 60px 80px 0 rgba(0, 0, 0, 0.3);
    > img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
  .Gcover_center {
    position: absolute;
    top: 50%;
    z-index: -1;
    margin-top: -6px;
    right: 0;
    left: 0;
    height: 77px;
    background: #02c6ff;
    .inner {
      position: relative;
      width: 1200px;
      margin: 0 auto;

      .tit_pr {
        position: absolute;
        top: 23px;
        left: 50%;
        width: 158px;
        margin-left: 53px;
        > img {
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .Gcovercontainer {
    .GcoverImage {
      margin-left: 0px;
      left: 2%;
    }
  }
}
@media screen and (max-width: 1220px) {
  .Gcovercontainer {
    .top_logo {
      width: 500px;
      margin: 0;
      margin-left: 20px;
    }
    .Gcover_center {
      .inner {
        width: 500px;
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .Gcovercontainer {
    .top_logo {
      width: 200px;
      margin: 0;
      margin-left: 20px;
    }
    .Gcover_center {
      display: none;
    }
  }
}
