.Einterviewcontainer {
  overflow: hidden;
  height: 1250px;
  padding-top: 400px;
  .con_box {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .Einter_subtitle {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .Einter_title {
      font-size: 36px;
      line-height: 1.7;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .Einter_text {
      overflow: hidden;
      color: #808080;
      font-size: 16px;
      line-height: 38px;
    }
    .con_txt {
      overflow: hidden;
      position: relative;
      float: left;
      width: 480px;
      height: 775px;
      margin-top: 250px;
      background-image: url(../../../../../_asset/images/emart/bg_interview.png);
      background-size: 480px 775px;
      .text1 {
        overflow: hidden;
        position: absolute;
        color: #222;
        font-size: 13px;
        line-height: 26px;
        top: 185px;
        right: 113px;
      }
      .text2 {
        bottom: 297px;
        left: 17px;
        overflow: hidden;
        position: absolute;
        color: #222;
        font-size: 13px;
        line-height: 26px;
      }
      .text3 {
        right: 119px;
        bottom: 189px;
        overflow: hidden;
        position: absolute;
        color: #222;
        font-size: 13px;
        line-height: 26px;
      }
      .text4 {
        left: 18px;
        bottom: 0;
        overflow: hidden;
        position: absolute;
        color: #222;
        font-size: 13px;
        line-height: 26px;
      }
    }
  }
}
