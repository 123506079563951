.DetailTeamContainer{
    background-color: #fff;
    .DetailTeamMain{
        padding: 0 30px;
        max-width: 1630px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .DetailTeamList.Detail{
        padding-bottom: initial;
    }
    .DetailTeamList{
        display: flex;
        border-bottom: 1px solid #222222;
        padding-top: 60px;
        padding-bottom: 100px;
    }
    .Detail{
        flex-direction: column;
        border-bottom: initial;
    }
    .DetailTeamTitleBox{
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        align-items: center;

    }
    .DetailTeamTitle{
        display: flex;
        flex: 1;
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 800;
    }
    .DetailTeamTeam{
        font-family: Montserrat;
        font-size: 14.5px;
        font-weight: 300;
        line-height: 1.38;
    }
    .DetailTeamSubBox{
        display: flex;
        flex-direction: column;
    }
    .DetailTeamSub{
        display: flex;
        flex: 4;
        font-family: NotoSansCJKkr;
        font-size: 30px;
        font-weight: 300;
        line-height: 1.67;
        color: #222222;
    }
    .HiringList{
        display: flex;
        text-align: center;
        line-height: 1.2;
    }
    .HiringListCircle{
        background-color: #222222;
        color: #fff;
        width: 182px;
        height: 182px;
        border-radius: 100px;
    }
    .DetailTeamSub.Intro{
        flex-direction: column;
    }
    .DetailTeamSubText{
        font-size: 40px;
        font-weight: 500;
        line-height: 1.38;
    }
    .BtnBox{
        margin-top: 40px;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        width: 342px;
        height: 70px;
        background-color: #222222;
        color: #fff;
        img{
            width: 35px;
            height: auto;
        }
    }
    .IntroBtn{
        font-size: 20px;
        font-weight: bold;
    }
    .DetailTeamSubImg img{
        width: 100%;
        height: auto;
    }
    .HiringListCircle{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .HiringListImg{
        width: 35px;
        height: auto;
        object-fit: contain;
        margin: 0 20px;
    }
    .HiringList:nth-child(5){
        img{
            display: none;
        }
    }
    .DetailTeamFormBtn{
        margin: auto;
        margin-top: 80px;
        margin-bottom: 100px;
        font-family: NotoSansCJKkr;
        font-size: 20px;
        font-weight: bold;
        width: 342px;
        height: 70px;
        background-color: #222222;
        color: #FFF;
    }
}

@media all and (max-width:1580px) {
    .DetailTeamContainer{
        .HiringListCircle{
            width: 165px;
            height: 165px;
        }
        .HiringListImg{
            margin: 0 10px;
        }
    }
}
@media all and (max-width:1440px) {
    .DetailTeamContainer{
        .DetailTeamSubText{
            font-size: 30px;
        }
        .DetailTeamSub{
            font-size: 25px;
        }
        .HiringListCircle{
            width: 150px;
            height: 150px;
        }
        .DetailTeamTitle{
            font-size: 22px;
            margin-right: 20px;
        }
        .HiringListImg{
            margin: 0 7px;
        }
    }
}
@media all and (max-width:1270px) {
    .DetailTeamContainer{
        .DetailTeamSubText{
            font-size: 27px;
        }
        .DetailTeamSub{
            font-size: 23px;
        }
        .HiringListCircle{
            width: 140px;
            height: 140px;
        }
        .DetailTeamTitle{
            font-size: 20px;
            margin-right: 20px;
        }
        .HiringListImg{
            margin: 0 5px;
        }
        .DetailTeamList{
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }
}
@media all and (max-width:1180px) {
    .DetailTeamContainer{
        .DetailTeamTitle{
            br{
                display: none;
            }
        }
        .DetailTeamSubText{
            font-size: 25px;
        }
        .DetailTeamSub{
            font-size: 22px;
            flex-wrap: wrap;
        }
        .HiringList{
            padding-bottom: 15px;
        }
        .HiringListCircle{
            width: 140px;
            height: 140px;
        }
        .DetailTeamTitle{
            font-size: 19px;
            margin-right: 20px;
        }
        .HiringListImg{
            margin: 0 5px;
        }
        .DetailTeamList{
            padding-top: 50px;
            padding-bottom: 80px;
        }
    }
}
//Mobile Tablet 미디어쿼리 분기점
@media all and (max-width:1024px) {
    .DetailTeamContainer{
        .DetailTeamList{
            flex-direction: column;
        }
        .DetailTeamMain{
            padding: 0 50px;
        }
        .DetailTeamSubText{
            font-size: 23px;
            font-weight: 300;
        }
        .DetailTeamSub{
            font-size: 23px;
            flex-wrap: wrap;
        }
        .BtnBox{
            width: 350px;
            height: 60px;
            img{
                width: 15px;
            }
        }
        .DetailTeamFormBtn{
            width: 350px;
            height: 60px;
            font-size: 18px;
        }
        .IntroBtn{
            font-size: 18px;
        }
        .HiringList{
            padding-bottom: 15px;
        }
        .HiringListCircle{
            width: 140px;
            height: 140px;
        }
        .DetailTeamTitle{
            font-size: 30px;
            margin-bottom: 30px;
        }
        .DetailTeamTeam{
            line-height: 2;
        }
        .DetailTeamTitleBox{
            margin-bottom: initial;
            align-items: end;
        }
    }
}

@media all and (max-width:767px) {
    .DetailTeamContainer{
        .DetailTeamList{
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 60px;
        }
        .DetailTeamMain{
            padding: 0 25px;
        }
        .DetailTeamSubText{
            font-size: 20px;
        }
        .DetailTeamSub{
            font-size: 20px;
        }
        .BtnBox{
            width: 300px;
            height: 50px;
            img{
                width: 15px;
            }
        }
        .DetailTeamFormBtn{
            width: 250px;
            height: 60px;
            font-size: 15px;
            margin-top: 50px;
            margin-bottom: initial;
        }
        .IntroBtn{
            font-size: 15px;
        }
        .HiringList{
            padding-bottom: 15px;
        }
        .HiringListCircle{
            width: 120px;
            height: 120px;
        }
        .DetailTeamTitle{
            font-size: 30px;
            margin-bottom: 20px;
        }
        .DetailTeamTeam{
            line-height: 2;
            font-size: 13px;
        }
        .DetailTeamTitleBox{
            margin-bottom: initial;
            align-items: end;
        }
    }
}
@media all and (max-width:500px) {
    .DetailTeamContainer{
        .DetailTeamSubText{
            font-size: 17px;
        }
        .DetailTeamSub{
            font-size: 17px;
        }
        .DetailTeamFormBtn{
            width: 100%;
            font-size: 13px;
        }
        .BtnBox{
            margin-top: 25px;
            img{
                width: 15px;
            }
        }
        .IntroBtn{
            font-size: 13px;
        }
        .HiringList{
            padding-bottom: 15px;
        }
        .HiringListCircle{
            width: 100px;
            height: 100px;
        }
        .HiringListImg{
            width: 17px;
            margin: 0 8px;
        }
        .DetailTeamTitle{
            font-size: 25px;
        }
        .DetailTeamTeam{
            line-height: 2;
            font-size: 13px;
        }
        .DetailTeamTitleBox{
            margin-bottom: initial;
            align-items: end;
        }
    }
}

@media all and (max-width:420px) {
    .DetailTeamContainer{
        .DetailTeamSubText{
            font-size: 15px;
        }
        .DetailTeamSub{
            font-size: 15px;
        }
        .DetailTeamFormBtn{
            font-size: 15px;
        }
        .BtnBox{
            width: 200px;
            height: 50px;
            margin-top: 20px;
            img{
                width: 13px;
            }
        }
        .IntroBtn{
            font-size: 13px;
        }
        .HiringList{
            padding-bottom: 15px;
        }
        .HiringListCircle{
            width: 80px;
            height: 80px;
        }
        .HiringListImg{
            width: 17px;
            margin: 0 8px;
        }
        .DetailTeamTitle{
            font-size: 22px;
        }
        .DetailTeamTeam{
            line-height: 2;
            font-size: 13px;
        }
        .DetailTeamTitleBox{
            margin-bottom: initial;
            align-items: end;
        }
    }
}

@media all and (max-width:420px){
    .DetailTeamContainer{
        .DetailTeamTitle{
            font-size: 20px;
        }
        .DetailTeamTeam{
            display: none;
        }
        .DetailTeamList{
            padding-top: 25px;
            padding-bottom: 40px;
        }
    }
}