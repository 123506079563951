.topzone-container {
   
    --heightHeroSection: 800px;
    --heightSaleSection: 900px;
    --fsTitleHerosection: 50px;
    --fsDetailHerosection: 22px;
    --imgWidthHeroSection: 600px;

    z-index: 1;
    background-color: white;
    width: 100%;
    overflow: hidden;
    font-family: PretendardMedium;

}