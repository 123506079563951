.FifthPage {
  display: flex;
  justify-content: center;
  background-color: #1a1a19;
  font-family: Montserrat;
  .Link {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;

    padding-top: 56.25%;
    width:100%;
    .link-container{
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 1950px;
      max-height: 1097px;
    }

    .LinkClickContainer{
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      height: 5%;
      bottom: 20%;
      width: 30%;
      left: 50%;
      transform: translateX(-54%);
      cursor: pointer;
    }
  }
  .BackImg{
    max-width: 1950px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height: auto;
    }
    
  }
}


// @media all and (max-width: 1750px) {
//     .FifthPage {
//       .Link {
//         width: 500px;
//       }
//     }
//   }

//   @media all and (max-width: 1550px) {
//     .FifthPage {
//       .Link {
//         width: 450px;
//       }
//     }
//   }

//   @media all and (max-width: 1440px) {
//     .FifthPage {
//       .Link {
//         height: 45px;
//         width: 420px;
//       }
//     }
//   }

//   @media all and (max-width: 1300px) {
//     .FifthPage {
//       .Link {
//         width: 370px;
//       }
//     }
//   }

//   @media all and (max-width: 1170px) {
//     .FifthPage {
//       .Link {
//         width: 340px;
//         height: 30px;
//       }
//     }
//   }

//   @media all and (max-width: 1024px) {
//     .FifthPage {
//       .Link {
//         width: 280px;
//       }
//     }
//   }

//   @media all and (max-width: 880px) {
//     .FifthPage {
//       .Link {
//         height: 25px;
//         width: 240px;
//       }
//     }
//   }

//   @media all and (max-width: 768px) {
//     .FifthPage {
//       .Link {
//         width: 220px;
//       }
//     }
//   }

//   @media all and (max-width: 700px) {
//     .FifthPage {
//       .Link {
//         height: 20px;
//         width: 190px;
//       }
//     }
//   }

//   @media all and (max-width: 580px) {
//     .FifthPage {
//       .Link {
//         height: 17px;
//         width: 160px;
//       }
//     }
//   }

//   @media all and (max-width: 510px) {
//     .FifthPage {
//       .Link {
//         height: 14px;
//         width: 140px;
//       }
//     }
//   }

//   @media all and (max-width: 420px) {
//     .FifthPage {
//       .Link {
//         height: 11px;
//         width: 120px;
//       }
//     }
//   }

//   @media all and (max-width: 320px) {
//     .FifthPage {
//       .Link {
//         height: 8px;
//         width: 90px;
//       }
//     }
//   }