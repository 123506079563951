.PimageChange_container {
  overflow: hidden;
  height: 800px;
  background: #f1f2f4 url(../../../../../_asset/images/Prorenata/any_prn1.gif)
    center top no-repeat;

  @media screen and (max-width: 480px) {
    background: #f1f2f4 url(../../../../../_asset/images/Prorenata/any_prn1.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
  }
}
