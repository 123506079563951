.serverkit_session8 {
    color: white;
    background-color: #6d42ff;
    padding: 193px 0 151px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 223px;
        text-align: center;

        .sk_left {
            display: flex;
            flex-direction: column;

            .sk_title {
                font-size: 50px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left;
                color: #f5f8ff;
                margin-bottom: 28px;
            }
            .sk_des {
                font-size: 26px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: left;
                color: #f5f8ff;
                margin-bottom: 63px;
            }
            .sk_button {
                display: flex;
                gap: 15px;
                .sk_item {
                    width: 90%;
                    min-width: 294px;
                    padding: 19px 0;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 17.5px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.89;
                    letter-spacing: 0.35px;
                    text-align: center;
                }
                .sk_black {
                    color: #fff;
                    background-color: #111;
                }
                .sk_white {
                    background-color: #fff;
                    color: #111;
                }
            }
        }

        @media screen and (max-width: 1400px) {
            flex-direction: column;

            .sk_button {
                flex-direction: column;
                align-items: center;
               
            }
            .sk_image {
                width: 90%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 80px 0 60px 0;

        .sk_main {
            .sk_left {
                display: flex;
                flex-direction: column;
                align-items: center;
                .sk_title {
                    width: 90%;
                    font-size: 25px;
                    text-align: center;
                }

                .sk_des {
                    width: 100%;
                    font-size: 15px;
                    text-align: center;
                    line-height: 1.7;
                }
                .sk_button{
                    .sk_item{
                        min-width: 250px;
                    }
                }
            }
        }
    }
}
