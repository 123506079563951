.Poverflow_container {
  overflow: hidden;
  height: 800px;
  background: #fff;
  .over_cont {
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .over_txt {
      padding-top: 20px;
      font-size: 13px;
      line-height: 1.7;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 767px) {
  .Poverflow_container {
    padding: 0 10px;
    .over_cont {
      width: 100%;
      display: flex;
      flex-direction: column;
      > div:last-child > img {
        width: 60%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
