.RequiredServicesBox {
    margin-bottom: 110px;

    .RequiredContainer {
        max-width: 1630px;
        width: 100%;
        margin: auto;
        .required__content {
        }
    }

    .RequiredServicesTitle {
        padding-top: 150px;
        font-family: Montserrat;
        font-size: 60px;
        color: #ffffff;
        max-width: 1630px;
        width: 100%;
        margin: auto;
        margin-bottom: 30px;
    }

    .RequiredExplanation {
        font-family: Montserrat;
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 100px;

        span {
            font-weight: 600;
        }
    }

    .Element {
        display: flex;
        align-items: center;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 36px;
    }
    .OtherElement {
        display: flex;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 36px;
    }
    .OtherBox {
        display: table-row;
        justify-content: center;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 300;
    }
    .ElementButton {
        margin-right: 16px;
        cursor: pointer;
    }

    .ElementCheckButton {
        margin-right: 16px;
    }

    .RequiredElementBox {
        display: flex;
    }

    .FirstRequiredElement {
        padding-right: 210px;
    }

    .SecondRequiredElement {
        padding-right: 210px;
    }
    .OtherTitle {
        margin-bottom: 10px;
    }
    .SmallElement {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        color: #ff641f;
    }
    .hrcss {
        width: 1630px;
        border: 1px solid #6c6c6c;
    }
    .secondhrcss {
        width: 1630px;
        margin-top: 68px;
        border: 1px solid #6c6c6c;
        margin-bottom: 10px;
    }

    .EnterProjectTitle {
        padding-top: 130px;
        font-family: Montserrat;
        font-size: 60px;
        color: #ffffff;
        max-width: 1630px;
        width: 100%;
        margin: auto;
        margin-bottom: 70px;
    }

    .InformationFillinBox {
        display: flex;
        margin-bottom: 20px;
        max-width: 1630px;
        width: 100%;
        margin: auto;
    }

    .LeftInformationBox {
        max-width: 1630px;
        width: 100%;
    }

    .ProjectNameBox {
        margin-bottom: 90px;
    }

    .RequiredElements {
        display: flex;
        margin-bottom: 34px;
    }
    .OtherRequiredElements {
        display: flex;
        margin-bottom: 44px;
    }

    .NameTitle {
        font-family: Montserrat;
        font-size: 18.5px;
        font-weight: 600;
        color: #6c6c6c;
    }

    .Emphasis {
        font-family: Montserrat;
        color: #ff641f;
        margin-left: 5px;
    }
    .ComentEmphasis {
        font-size: 14px;
        font-family: Montserrat;
        color: #ffffff;
        margin-left: 20px;
        font-weight: 300;
        margin-top: 3px;
    }

    .SelectTitle {
        max-width: 596px;
        max-height: 100px;
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
        input {
            height: 40px;
            width: 100%;
            padding-left: 14px;
            background-color: #333;
            font-size: 30px;
            font-weight: 500;
            font-family: Montserrat;
            border: none;
            background-color: transparent;
            color: #ffffff;

            &:focus {
                outline: initial;
            }
        }
    }
}
@media all and (max-width: 1200px) {
    .RequiredServicesBox {
        .SelectTitle {
            font-size: 2vw !important;
            input {
                font-size: 2vw !important;
            }
        }
    }
}

@media all and (max-width: 767px) {
    .RequiredServicesBox {
        margin-bottom: 20px;

        .RequiredContainer {
            max-width: 1630px;
            width: 100%;
            margin: auto;
            .buttonWrapper {
                height: 8vw !important;
            }
        }

        .RequiredServicesTitle {
            padding-top: 10vw;
            font-family: Montserrat;
            font-size: 3.5vw;
            color: #ffffff;
            max-width: 1630px;
            width: 100%;
            margin: auto;
            margin-bottom: 12px;
        }

        .RequiredExplanation {
            font-family: Montserrat;
            font-size: 1.5vw;
            color: #ffffff;
            margin-bottom: 30px;

            span {
                font-weight: 600;
            }
        }

        .Element {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 14px;
        }
        .OtherElement {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 12px;
        }
        .OtherBox {
            display: table-row;
            justify-content: center;
            color: #ffffff;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 300;
        }
        .ElementButton {
            margin-right: 12px;
            cursor: pointer;
            width: 11vw;
            height: 11vw;
        }

        .ElementCheckButton {
            margin-right: 16px;
        }

        .RequiredElementBox {
            display: table-row;
        }

        .FirstRequiredElement {
            padding-right: 0px;
        }

        .SecondRequiredElement {
            padding-right: 0px;
        }
        .OtherTitle {
            margin-bottom: 4px;
            font-size: 14px;
        }
        .SmallElement {
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 300;
            color: #ff641f;
        }
        .hrcss {
            width: 1630px;
            border: 1px solid #6c6c6c;
        }
        .secondhrcss {
            width: 1630px;
            margin-top: 68px;
            border: 1px solid #6c6c6c;
            margin-bottom: 10px;
        }

        .EnterProjectTitle {
            padding-top: 130px;
            font-family: Montserrat;
            font-size: 60px;
            color: #ffffff;
            max-width: 1630px;
            width: 100%;
            margin: auto;
            margin-bottom: 70px;
        }

        .InformationFillinBox {
            display: flex;
            margin-bottom: 20px;
            max-width: 1630px;
            width: 100%;
            margin: auto;
        }

        .LeftInformationBox {
            max-width: 1630px;
            width: 100%;
        }

        .ProjectNameBox {
            margin-bottom: 90px;
        }

        .RequiredElements {
            display: flex;
            margin-bottom: 34px;
        }
        .OtherRequiredElements {
            display: flex;
            margin-bottom: 44px;
        }

        .NameTitle {
            font-family: Montserrat;
            font-size: 18.5px;
            font-weight: 600;
            color: #6c6c6c;
        }

        .Emphasis {
            font-family: Montserrat;
            color: #ff641f;
            margin-left: 5px;
        }
        .ComentEmphasis {
            font-size: 14px;
            font-family: Montserrat;
            color: #ffffff;
            margin-left: 20px;
            font-weight: 300;
            margin-top: 3px;
        }

        .SelectTitle {
            max-width: 596px;
            max-height: 100px;
            font-family: Montserrat;
            font-size: 30px;
            color: #6c6c6c;
            font-weight: 500;
            input {
                height: 40px;
                width: 100%;
                padding-left: 14px;
                background-color: #333;
                font-size: 30px;
                font-weight: 500;
                font-family: Montserrat;
                border: none;
                background-color: transparent;
                color: #ffffff;

                &:focus {
                    outline: initial;
                }
            }
        }
    }
}
