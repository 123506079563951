// 백그라운드 영상 overflow 에 따른 Hidden 처리
.fp-section.fp-table,
.fp-slide.fp-table {
  overflow: hidden;
}
.SecondPage {
  background-color: #ff641f !important;
  // background-image: url('../../../_asset/images/mainback/bg-02.png');
  // background-repeat: no-repeat;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  &__text {
    position: absolute;
    top: 22vh;
    right: 0;
    z-index: 99999;
    font-family: Montserrat;
    width: 65%;
    display: flex;
    white-space: nowrap;
    color: black;

    .content {
      position: relative;
      height: 10vw;
      min-width: 4000px;

      vertical-align: bottom;
      .title {
        text-transform: uppercase;
        height: 100%;
        span {
          font-size: 170px;
          font-weight: 600;
        }
      }
      .number,
      .subtitle {
        text-transform: none;
        position: absolute;
        font-size: 14px;
        top: 15px;
        font-weight: 700;
      }
      .number {
        right: 95%;
      }
      .subtitle {
        right: 80%;
      }
    }
  }
  &__designText {
    position: absolute;
    top: 38vh;
    left: -150px;
    z-index: 99999;
    font-family: Montserrat;
    display: flex;
    white-space: nowrap;
    color: black;
    .content {
      position: relative;
      height: 10vw;
      vertical-align: bottom;
      .title {
        text-transform: uppercase;
        height: 100%;
        span {
          font-size: 170px;
          font-weight: 600;
        }
      }
    }
  }
  &__thirdText {
    position: absolute;
    top: 60vh;
    right: 0;
    text-align: right;
    z-index: 99999;
    font-family: Montserrat;
    width: 4000px;
    display: flex;
    white-space: nowrap;
    color: black;
    .content {
      width: 100%;
      position: relative;
      height: 8vw;
      vertical-align: bottom;
      .title {
        text-transform: uppercase;
        height: 100%;
        span {
          font-size: 170px;
          font-weight: 600;
        }
      }
      .number,
      .subtitle {
        text-transform: none;
        position: absolute;
        font-size: 14px;
        top: 15px;
        font-weight: 700;
      }
      .number {
        right: 72vw;
      }
      .subtitle {
        right: 25vw;
      }
    }
  }
  .video-wrap {
    z-index: 1;
    //  top:0;
    //  left: 0;
    //  width: 100%;
    //  height: auto;
    display: flex;
    justify-items: center;
  }

  .BackImg {
    max-width: 1950px;
    max-height: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50vw;
    }
  }

  .bg-background-video {
    height: 100vh;
    position: relative;
  }
  .animation-video {
    position: absolute;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  // .Link {
  // 	height: 100%;
  // 	display: flex;
  // 	justify-content: center;
  // 	align-items: flex-end;
  // 	padding-bottom: 155px;
  // 	position: absolute;
  // 	width: 100%;
  // 	z-index: 9999;
  // 	top: 0;
  // 	left: 0;
  // }
}
@media all and (min-width: 1920px) {
  .SecondPage {
  }
}

@media all and (max-width: 1200px) {
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .SecondPage {
    .Link {
      padding-bottom: 130px;
    }
  }
}

@media all and (max-width: 968px) {
  .SecondPage {
    .BackImg {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100vw;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__text {
      .content {
        width: 2000px;
        .title {
          span {
            font-size: 80px !important;
            margin-left: auto !important;
          }
        }
        .number,
        .subtitle {
          top: -5px;
        }
        .number {
          right: 0;
          left: 7vw;
        }
        .subtitle {
          right: 0;
          left: 23vw;
        }
      }
    }
    &__designText {
      left: -70px;
      .content {
        .title {
          span {
            font-size: 80px !important;
            margin-left: auto !important;
          }
        }
      }
    }
    &__thirdText {
      .content {
        .title {
          span {
            font-size: 80px !important;
            margin-left: auto !important;
          }
        }
        .number,
        .subtitle {
          top: -5px;
        }
      }
    }
  }
}
@media all and (max-width: 430px) {
  .SecondPage {
    &__text {
      right: 35px;
      .content {
        .title {
          span {
            font-size: 55px !important;
            margin-left: auto !important;
          }
        }
      }
    }
    &__designText {
      top: 30vh;
      .content {
        .title {
          span {
            font-size: 55px !important;
            margin-left: auto !important;
          }
        }
      }
    }
    &__thirdText {
      top: 40vh;
      .content {
        .title {
          span {
            font-size: 55px !important;
            margin-left: auto !important;
          }
        }
      }
    }
  }
}

@media all and (min-width: 431px) and (max-width: 968px) {
  .SecondPage {
    &__text {
      right: 24px;
    }
    &__designText {
      top: 33vh;
    }
    &__thirdText {
      top: 44vh;
    }
  }
}
@media all and (min-width: 699px) and (max-width: 1024px) {
  .SecondPage {
    &__text {
      right: 24px;
    }
    &__designText {
      top: 33vh;
    }
    &__thirdText {
      top: 48vh;
    }
  }
}
@media all and (min-width: 969px) and (min-height: 680px) {
  .SecondPage {
    &__text {
      right: 30vh;
    }
    &__designText {
      top: 50vh;
    }
    &__thirdText {
      top: 75vh;
    }
  }
}
