 .section5 {
     --heightSection5: 900px;
     --width: 1280px;
     --imgWidth: 151px;
     height: var(--heightSection5);
     display: flex;
     justify-content: center;
     flex-direction: column;


     &-container {
         margin: 0 auto;
         width: var(--width);
         padding: 0 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;


         .title {
             font-size: 50px;
             font-weight: bold;

             @media screen and (max-width: 767px) {
                 font-size: 32px;
             }
         }

         .detail {
             font-size: 22px;
             padding-top: 25px;

             @media screen and (max-width: 767px) {
                 font-size: 16px;
             }
         }

         .list {

             display: flex;
             gap: 20px;
             justify-content: space-around;
             width: 100%;
             flex-wrap: wrap;
             padding: 0 20px;
             padding-top: 100px;

             &-item {
                 display: flex;
                 flex-direction: column;
                 align-items: center;
                 justify-content: center;


                 img {
                     width: var(--imgWidth);
                 }

                 .item-content {
                     padding-top: 40px;
                     color: #444;
                     white-space: pre-line;
                     text-align: center;

                 }
             }
         }

         .button {
             padding-top: 150px;

             &-component {
                 width: 216px;
                 height: 70px;
                 background-color: #00a6fc;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 border-radius: 35px;
                 font-size: 26px;
                 color: #fff;
                 cursor: pointer;

               
             }
                           @media screen and (max-width: 767px) {
                            padding-top: 50px;
                             &-component {
                                width: 166px;
                                    height: 50px;
                                    font-size: 20px;
                             }
                           }
         }
     }

     @media screen and (max-width: 1280px) {
         --width: 90%;
         --imgWidth: 121px;
     }

     @media screen and (max-width: 767px) {
         --imgWidth: 71px;
         --heightSection5: 800px
     }

     @media screen and (max-width: 310px) {
         --imgWidth: 41px;
     }
 }