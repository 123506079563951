
.ButtonWhiteContainer{
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  font-weight: 500;
  .menubtn {
    margin-right: 4px;
    color: #222222;
    font-family: Montserrat;
  }
  .btn:hover .menubtn{
    color:#ff510d;
  }
  .btn{
    padding-right: initial;
  }
  .btn a{
    display: flex;
    flex-direction: row;
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:1024px) {
  .ButtonWhiteContainer{
    .menubtn{
      line-height: 1;
    }
    .btn{
      align-items: flex-start;
      font-size: 16px;
    }
    .btn.about{
      display: none;
    }
  }
}

@media all and (max-width:400px) {
  .ButtonWhiteContainer{
    .btn{
      font-size: 14px;
    }
  }
}

@media all and (max-width:300px) {
  .ButtonWhiteContainer{
    .btn{
      font-size: 12px;
    }
  }
}