.Eoverflowcontainer {
  height: 1250px;
  padding-top: 300px;
  background: #f2f2f2 url(../../../../../_asset/images/emart/bg_emart.png)
    center bottom no-repeat;
  background-size: 1200px 542px;
  .con_bx {
    width: 1200px;
    margin: 0 auto;
    .Eover_title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
    }
    .Eoveer_subtext {
      font-size: 16px;
      line-height: 38px;
      color: #808080;
      margin-bottom: 100px;
    }
    .Eover_Pro {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 50px;
    }

    .Pro_ListBox {
      display: flex;
      flex-direction: column;

      .titlebox {
        .title {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 50px;
        }
      }
      .textbox {
        .text {
          margin-bottom: 25px;
          color: #222;
          font-size: 16px;
        }
      }
    }
  }
}
