.ContactContainer {
    padding-top: 261px;
    .ContactMain {
        max-width: 1630px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        font-family: Montserrat;
        color: #fff;
        box-sizing: border-box;
    }
    .ContactMiddleBox {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 99px;
    }
    .ContactLeftBox {
        flex: 1;
        padding-right: 50px;
        box-sizing: border-box;
    }
    .ContactRightBox {
        flex: 1;
        padding-left: 50px;
        box-sizing: border-box;

    }
    .RightText {
        font-size: 80px;
        font-weight: 500;
        line-height: 1;
        text-align: left;
        margin-bottom: 199px;
    }
    .Title {
        font-size: 18.5px;
        font-weight: 500;
        line-height: 1;
        color: #6c6c6c;
        margin-bottom: 39px;
    }
    .InfoTitle {
        font-size: 18.5px;
        font-weight: 500;
        line-height: 1;
        color: #6c6c6c;
    }
    .InfoText {
        max-width: 610px;
        width: 100%;
        border-bottom: 1px solid #fff;
    }
    .ContactTitle {
        color: #fff;
        padding: 40px 0;
        font-family: Montserrat;
        font-size: 40px;
        font-weight: normal;
        line-height: 1;
        text-align: left;
    }
    .SnsBox {
        padding: initial;
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            &:hover {
                border-bottom: 1px solid;
            }
        }
    }
    .SnsTitle {
        font-family: Montserrat;
        font-size: 40px;
        font-weight: normal;
        line-height: 1.38;
        text-align: left;
        color: #fff;
        margin-right: 8px;
    }
    .AddressText {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.6;
        text-align: left;
    }
    .Call {
        margin-bottom: 99px;
    }
}

@media all and (max-width: 1280px) {
    .ContactContainer {
        .ContactMain {
            .ContactMiddle {
                .ContactRightBox {
                    padding-left: 30px;
                }
                
            }
            .ContactLeftBox{
                padding-right: 30px;
            }
        }
    }
}

// @media all and (max-width: 968px) {
//     .ContactContainer {
//         .ContactMain {
//         }
//         .ContactMiddleBox {
//             display: table-row;
//             flex-direction: row;
//             align-items: flex-start;
//             margin-bottom: 99px;
//         }
//         .ContactLeftBox {
//             flex: 1;
//         }
//         .ContactRightBox {
//             flex: 1;
//         }
//         .RightText {

//         }
//         .Title {

//         }
//         .InfoTitle {

//         }
//         .InfoText {

//         }
//         .ContactTitle {

//         }
//         .SnsBox {
//             padding: initial;
//             a {
//                 display: flex;
//                 flex-direction: row;
//                 align-items: center;
//                 &:hover {
//                     border-bottom: 1px solid;
//                 }
//             }
//         }
//         .SnsTitle {
//             font-family: Montserrat;
//             font-size: 20px;
//             font-weight: normal;
//             line-height: 1.38;
//             text-align: left;
//             color: #fff;
//             margin-right: 8px;
//         }
//         .AddressText {
//             font-size: 20px;
//             font-weight: normal;
//             line-height: 1.6;
//             text-align: left;
//         }
//         .Call {
//             margin-bottom: 39px;
//         }

//         .SnsIcon {
//             width: 5vw;
//         }
//     }
// }

@media all and (max-width: 968px) {
    .ContactContainer {
        padding-top: 180px;
        .ContactMain {
            max-width: 1630px;
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            font-family: Montserrat;
            color: #fff;
            padding: 0px 50px 0px 50px;
        }
        .ContactMiddleBox {
            // display: table-row;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 99px;
            justify-content: space-between;
        }
        .ContactLeftBox {
            flex: 1;
            padding-right: 20px;
            box-sizing: border-box;
        }
        .ContactRightBox {
            flex: 1;
            padding-left: 30px;
        }
        .RightText {
            font-size: 30px;
            font-weight: 500;
            line-height: 1;
            text-align: left;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .Title {
            font-size: 14.5px;
            font-weight: 500;
            line-height: 1;
            color: #6c6c6c;
            margin-bottom: 10px;
        }
        .InfoTitle {
            font-size: 14.5px;
            font-weight: 500;
            line-height: 1;
            color: #6c6c6c;
        }
        .InfoText {
            max-width: 610px;
            width: 100%;
            border-bottom: 1px solid #fff;
        }
        .ContactTitle {
            color: #fff;
            padding: 14px 0;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: normal;
            line-height: 1;
            text-align: left;
        }
        .SnsBox {
            padding: initial;
            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                &:hover {
                    border-bottom: 1px solid;
                }
            }
        }
        .SnsTitle {
            font-family: Montserrat;
            font-size: 20px;
            font-weight: normal;
            line-height: 1.38;
            text-align: left;
            color: #fff;
            margin-right: 8px;
        }
        .AddressText {
            font-size: 20px;
            font-weight: normal;
            line-height: 1.6;
            text-align: left;
        }
        .Call {
            margin-bottom: 39px;
        }

        .SnsIcon {
            width: 5vw;
        }
    }
}
@media all and (max-width: 500px) {
    .ContactContainer {
        padding-top: 130px;
        .ContactRightBox {
            padding-top: 0 !important;
            padding-left: 10px;
            box-sizing: border-box;
        }
        .RightText {
            font-size: 22px;
        }
        .InfoTitle {
            font-size: 10px;
        }
        .InfoText {
            button {
                font-size: 10px;
            }
        }
        .Title {
            font-size: 10px;
        }
        .SnsTitle {
            font-size: 10px;
        }
        .AddressText {
            font-size: 10px;
        }
    }
}

@media all and (max-width: 400px) {
    .ContactContainer {
        .ContactMain {
            padding: 0 10px;
        }
    }
}
