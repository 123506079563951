.strategycontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  max-width: 1226px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    .strategy_top {
      .sc-dhKdcB {
        font-size: 30px !important;
        text-align: right;
        letter-spacing: 10px !important;
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 978px) {
    #overflow_video {
      width: 500px;
    }
  }
  .List_map {
    color: #fff;

    .list_top {
      display: block;
      margin-bottom: 45px;
      padding-bottom: 115px;
      border-bottom: 6px solid #fff;

      .number {
        font-size: 40px;
        font-weight: 600;
        @media screen and (max-width: 480px) {
          font-size: 24px;
        }
      }
      .title {
        margin-top: 50px;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 1.2px;
        @media screen and (max-width: 480px) {
          font-size: 24px;
        }
      }
    }
    .list_bottom {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: 20px;
        .em {
          font-size: 24px !important;
        }
      }
      @media screen and (min-width: 481px) and (max-width: 980px) {
        flex-direction: column;
        gap: 20px;
      }
      .em {
        font-size: 40px;
        font-weight: 900;
        flex: 1;
      }
      .text {
        flex: 1.2;
        font-size: 14px;
        line-height: 2;
      }
    }
  }
}
