.Ppictures_container {
  overflow: hidden;
  .p_cont_1 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    background: url(../../../../../_asset/images/Prorenata/bg_prn8.png) center
      top no-repeat;
    background-size: cover;
    .Ppictures_bx {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn34.png)
        center top no-repeat;
    }
  }
  .Ppictures_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/img_prn35.png) center
      top repeat-x;
  }
  .Ppictures_cont_3 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: #f4f3f7
      url(../../../../../_asset/images/Prorenata/img_prn36.png) center top
      no-repeat;
  }
  .Ppictures_tit {
    overflow: hidden;
    width: 960px;
    height: 202px;
    margin: 0 auto;
    padding-top: 49px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    .Left_bx {
      font-size: 12px;
      font-weight: 300;
    }
    .Right_bx {
      font-size: 12px;
      font-weight: 300;
    }
  }
}
