.Emartcover {
  overflow: hidden;
  height: 1280px;
  padding-top: 300px;
  background: url(../../../../../_asset/images/emart/bg_cover.png) center top
    no-repeat;
  background-size: cover;
  .con_bx {
    width: 1200px;
    margin: 0 auto;

    .tit_bx {
      float: left;
      width: 418px;
      padding-left: 50px;
    }
    .logo_box {
      .Ecover_text {
        .Ecover_title {
          font-size: 35px;
          font-weight: 900;
          margin-top: 100px;
        }
        .Ecover_subtitle {
          margin-top: 50px;
          line-height: 1.7;
          font-size: 20px;
        }
      }
    }
  }
  .Ecover_Img {
    .Ecover_Wrap {
      overflow: hidden;
      float: left;
      width: 364px;
      border: 15px solid #222;
      border-radius: 48px;
      box-shadow: 15px 39px 36px rgba(34, 34, 34, 0.5);
    }
  }
}
