.serverkit_session1 {
    color: white;
    background-color: #fff;

    .serverkit_header {
        height: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 17.4%;
        position: relative;

        .sk_logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .sk_right {
            display: flex;
            gap: 10px;
            .sk_content {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 4;
                letter-spacing: normal;
                text-align: center;
                color: #f6f6f6;
                border-radius: 6px;
                background-color: #4d23d5;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 24px;
            }
        }

        .header__btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .header__language {
            width: 151px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border: 1px solid ;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 10px;
            background-color: #0142ff
            
        }
        .header__login {
            border-radius: 5px;
            cursor: pointer;
            background-color: #fe5a60;
            color: white;
            text-align: center;
            width: 151px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 16px;
        }

        @media screen and (max-width: 768px) {
            padding: 0 5%;
            height: 40px;

            .sk_logo {
                img {
                    height: 20px;
                }
            }
            .header__btn{
                display: none;
            }
           
        }

        @media screen and (max-width: 1400px) {
            justify-content: space-between;
            padding: 0 10%;

            .sk_logo {
                display: block;
                position: relative;
                left: unset;
                top: unset;
                transform: unset;
            }
        }
    }

    .sk_main {
        background-color: #9d80ff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 124px;

        .sk_text {
            display: flex;
            flex-direction: column;
            gap: 43px;
            .sk_top {
                margin: none;
                font-size: 62.5px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.11;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
            .sk_bottom {
                font-size: 27.5px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.35;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                span {
                    background-color: #6d42ff;
                    padding: 3px 2px;
                    @media screen and (max-width: 767px) {
                            display: inline-block;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            padding: 0 5%;

            .sk_logo {
                img {
                    height: 30px;
                }
            }

            .sk_bottom{
                font-size: 14px;
            }
        }

        @media screen and (max-width: 1400px) {
            flex-direction: column;
            padding: 40px 10%;

            .sk_text {
                width: 100%;
                justify-content: flex-start;
                font-size: 16px;
                
                .sk_top {
                    font-size: 25px;
                }
                .sk_bottom {
                    font-size: 22px;
                }
            }

            .sk_image {
                width: 90%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
