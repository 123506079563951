.HiringPopUpContainer{
    .HiringPopUpMain{
        font-family: NotoSansKr;
        text-align: center;
    }
    .HiringPopUpTextBox{
        font-size: 16px;
        padding: 50px 40px;
    }
    .HiringPopUpTitle{
        font-weight: bold;
        margin-bottom: 25px;
    }
    .HiringPopUpSub{
        font-weight: normal;
        span{
            color: #ff510d;
        }
    }
    .HiringPopUpCheck{
        font-family: NotoSansKr;
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        padding: 20px 0;
        border-top: 1px solid #eeeeee;
        color: #ff510d;
    }
}
.MuiPaper-rounded {
    border-radius: 10px !important;
}

@media all and (max-width:550px) {
    .HiringPopUpContainer{
        .HiringPopUpTextBox{
            font-size: 14px;
        }
        .HiringPopUpCheck{
            font-size: 14px;
        }
    }
}
@media all and (max-width:470px) {
    .HiringPopUpContainer{
        .HiringPopUpTextBox{
            padding: 30px 20px;
        }
        .HiringPopUpTextBox{
            font-size: 14px;
        }
        .HiringPopUpCheck{
            font-size: 14px;
        }
        .HiringPopUpSub{
            br{
                display: none;
            }
        }
    }
}

@media all and (max-width:350px) {
    .HiringPopUpContainer{
        .HiringPopUpTextBox{
            padding: 30px 20px;
        }
        .HiringPopUpTextBox{
            font-size: 12px;
        }
        .HiringPopUpCheck{
            font-size: 12px;
        }
        .HiringPopUpSub{
            br{
                display: none;
            }
        }
    }
}

