.launchcontainer {
  overflow: hidden;
  position: relative;
  .launch_top {
    position: relative;
    z-index: 10;
    max-width: 1226px;
    width: 100%;
    margin: 250px auto 0;
    @media screen and (max-width: 480px) {
      margin-top: 50px;
      .swucL {
        font-size: 30px;
        letter-spacing: 20px;
      }
    }
  }
  .launch_Wrap {
    overflow: visible;
    position: relative;
    margin-top: 150px;
    text-align: center;
    width: 100%;
    @media screen and (max-width: 480px) {
      margin-top: 0;
    }
  }
  .launch_vd {
    position: relative;
    z-index: 10;
    width: 326px;
    height: 707px;
    border-radius: 30px;
    border: 10px solid #000000;
    @media screen and (max-width: 480px) {
      width: 264px;
      height: 570px;
    }
  }
  .thunmb {
    position: relative;
    max-width: 1226px;
    width: 100%;
    margin: -145px auto 0;
    padding-bottom: 512px;
    text-align: center;
    @media screen and (max-width: 480px) {
      padding-bottom: 300px;
    }
  }
}
