.creditcontainer {
  .credit_top {
    overflow: hidden;
    max-width: 1226px;
    width: 100%;
    margin: 262px auto 0;
    text-align: center;
    @media screen and (max-width: 480px) {
      margin-top: 0;
      .cabxBY {
        opacity: 1;
        margin-top: 0;
      }
      .bFfEOQ {
        opacity: 1;
        font-size: 12px;
      }
    }
    > img {
      max-width: 1201px;
      max-height: 827px;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 480px) {
        margin-top: 0;
      }
    }
  }
}
