 .section3 {
     --pt: 139px;
     --widthImgLeft: 592px;
     --heightSection3: 900px;
     --fs: 36px;
    --widthRightItem: 450px;
    --heightRightItem: 110px;
    --fsRightTitle: 50.5px;
    --fsRightDetail: 24px;
    --sizePlusIcon: 51px;

     max-width: 1280px;
     padding: 0 20px;
     margin: 0 auto;
     height: var(--heightSection3);
     display: flex;
     justify-content: center;
     flex-direction: column;
     text-align: center;

     &-container {

         display: flex;
         flex-direction: row;
         background-color: #fff;
         gap: 40px;
         align-items: center;

         @media screen and (max-width: 767px) {
             flex-direction: column;
         }

         .left {
             width: 50%;
             display: flex;
             align-items: center;
             flex-direction: column;

             img {
                 max-width: var(--widthImgLeft);
                 max-height: 372px;
             }

             @media screen and (max-width: 767px) {
                 width: 100%;
             }
         }

         .right {
             display: flex;
             align-items: center;
             flex-direction: column;
             width: 50%;
            &-title {
                font-size: var(--fsRightTitle);
                color: #00a6fc;
                font-weight: bold;
                text-align: center;
            }
            &-content {
                padding-top: 10px;
                &-item {
                    background-color: #c8ecff;
                    margin-top: 15px;
                    width: var(--widthRightItem);
                    height: var(--heightRightItem);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    font-size: var(--fsRightDetail);
                    color: #333333;
                    overflow: visible;
                    border-radius: 10px;
                    .icon {
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translate(-50%,60%);
                        width: var(--sizePlusIcon);
                        height: var(--sizePlusIcon);
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        z-index: 9999;
                    }
                }
                .light-color {
                    background-color: #e5f6ff;
                }
                .blood-color {
                    background-color: #85c9ed;
                }
            }
         }

         @media screen and (max-width: 1279px) {
             align-items: center;
         }
     }

     &-content {
         padding-top: var(--pt);
         font-size: var(--fs);
         font-weight: bold;

         span {
             padding-left: 3px;
         }

         .blue-color {
             color: #00a6fc;
         }

         .green-color {
             color: #00d794;
         }
     }

     @media screen and (max-width: 1279px) {
         --widthImgLeft: 500px;
         --widthImgRight: 360px;
     }

     @media screen and (max-width: 1079px) {
         --widthImgLeft: 400px;
         --widthImgRight: 360px;
        --widthRightItem: 320px;
            --heightRightItem: 90px;
            --fsRightTitle: 40.5px;
            --fsRightDetail: 22px;
            --sizePlusIcon: 45px;
     }

     @media screen and (max-width: 767px) {
         --fs: 24px;
         --heightSection3: 800px;
         --pt: 39px;

            --fsRightTitle: 32.5px;
            --fsRightDetail: 20px;
            --sizePlusIcon: 40px;
     }

     @media screen and (max-width: 420px) {
         --widthImgLeft: 100%;
         --widthImgRight: 100%;
         --heightSection3: 700px;
        --widthRightItem: 280px;
     }
           @media screen and (max-width: 300px) {
               --widthRightItem: 250px;
           }
 }