.Gfooter_container {
  position: relative;
  width: 100%;
  .story_inner {
    position: relative;
    padding: 40px 0;
  }
  .member {
    background: #333;
    padding: 187px 0 168px;
    .inner {
      position: relative;
    }
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
