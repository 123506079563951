.Ecataloguecontainer {
  overflow: hidden;
  margin-top: 203px;
  .con_card {
    position: relative;
    overflow: hidden;
    width: 1200px;
    height: 1594px;
    margin: 0 auto;
    .tit_bx {
      overflow: hidden;
      margin-left: 221px;
      .sub_text {
        margin-top: 35px;
        color: #808080;
        font-size: 16px;
        font-weight: 500;
        line-height: 38px;
      }
      .bg {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 1200px;
        background: #ffe346;
        border-radius: 100% 100%;
      }
    }
    .card {
      display: flex;
      position: absolute;
      top: 203px;
      width: 100%;
      z-index: 10;
      font-size: 0;
      text-align: center;
      justify-content: center;
      .catalogueWrap {
        margin-bottom: 68px;
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
    .txt {
      .txt_bottom {
        overflow: hidden;
        position: absolute;
        left: 221px;
        bottom: 49px;
        z-index: 30;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.7;
      }
      > img {
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 20;
        width: 100%;
        height: 600px;
      }
    }
  }
}
