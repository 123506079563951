.MenuViewMain,
.MenuViewMain.hide {
  // //slide out-right
  // -webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  //         animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

  display: flex;
  user-select: none;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0);
  overflow: hidden scroll;
  pointer-events: none;
  font-weight: 700;
  transition: backdrop-filter 0.3s ease 0s, -webkit-backdrop-filter 0.3s ease 0s;
}
.MenuImg {
  margin-left: 5px;
}
.MenuViewMain.show {
  pointer-events: all;
}
.MenuRightText a {
  cursor: pointer;
}
.MenuViewMain,
.MenuViewMain--on {
  z-index: 1100;
}

.MenuViewDimd {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  transition: opacity 0.3s ease 0s, background-color 0.5s ease 0s;
  opacity: 0;
}
.MenuRightTitle {
  z-index: 1;
  color: #ffffff;
}
.MenuRightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.MenuRightList {
  display: flex;
  justify-content: start;
  font-family: Montserrat;
  line-height: 38.5px;
}
.MenuRightSubList {
  :hover {
    color: rgba(255, 81, 13, 1);
  }
}
.MenuRightSubList.Sharing {
  opacity: 0.3;
  :hover {
    cursor: initial;
    color: #ffffff;
    user-select: none;
  }
}
.Circle {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-family: NotoSansKR;
  font-weight: 500;
  top: -10px;
  right: 10px;
  position: absolute;
  width: 45px;
  height: 20px;
  background-color: rgba(255, 81, 13, 1);
  border-radius: 45px;
}
.MenuRightTitle {
  margin-right: 60px;
  width: 120px;
  font-size: 20px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.3);
  a {
    color: rgba(255, 255, 255, 0.3);
  }
}
.MenuRightText {
  font-size: 20px;
  font-weight: 300;
  :hover {
    transition: color 0.3s ease 0s;
  }
}
.MenuViewMain.show .MenuViewDimd {
  opacity: 0.96;
}
.MenuBox {
  display: flex;
  flex-direction: row;
}
.MenuLeftTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 95px;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 125.5px;
}
.MenuLeftItem {
  display: inline-block;
  line-height: 1.2;
}
.MenuBox a {
  position: relative;
  display: flex;
  align-items: center;
}
.MenuLeftTitle .MenuLeftItem:hover .MenuLeftText > span {
  color: #ff510d;
}
.MenuBox a > span {
  pointer-events: none;
}
.MenuLeftTitle .MenuLeftText::before {
  content: "";
  transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  position: absolute;
  width: 0px;
  height: 0.06em;
  bottom: 70px;
  left: 0px;
  opacity: 0.5;
  background-color: rgba(255, 81, 13, 0.8);
  z-index: 1000;
}
.MenuLeftTitle .MenuLeftItem:hover .MenuLeftText::before {
  width: 100%;
  left: 0px;
  opacity: 1;
  transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  color: rgba(255, 81, 13, 0.8);
}
.MenuLeftText {
  color: #ffffff;
  display: inline-block;
  position: relative;
}
.MenuLeftTitle .MenuLeftItem a:hover {
  color: rgba(255, 81, 13, 0.8);
}
.mask-text {
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.menu-view__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  width: 300px;
  line-height: 1.54em;
  font-size: 28px;
  transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
  transform: translateX(20%);
  opacity: 0;
}

.menu-view.show .menu-view__right {
  opacity: 1;
  transform: translateX(0px);
}

.menu-view__right .menu-view__right-item > a {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  color: white;
}

.menu-view__right .menu-view__right-item:hover > a {
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transform: translate(-0.25em, -0.2em);
}

.menu-view__right .menu-view__right-item > a span {
  display: block;
}

.menu-view__right .menu-view__right-item.menu-hr {
  border-top: 1px solid #222222;
  margin-top: 25px;
  padding-top: 25px;
}

.menu-view__right .menu-view__right-item > a span.menu-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4em;
}

.menu-view__footer {
  margin-top: 12%;
  padding-top: 12%;
  border-top: 1px solid transparent;
  font-size: 4.5vw;
  line-height: 2.1em;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 0.2s ease 0s;
  transform: translateX(20%);
  opacity: 0;
}

.menu-view.show .menu-view__footer {
  border-color: rgb(0, 0, 0);
}

.menu-view.show .menu-view__footer {
  opacity: 1;
  transform: translateX(0px);
}

.menu-view__footer .copyright {
  transition: opacity 0.3s ease 0s;
  opacity: 0;
  font-size: 3.5vw;
  margin-top: 9%;
}

.menu-view.show .menu-view__footer .copyright {
  opacity: 1;
}

.menu-view__theme-change {
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 50px;
  bottom: 50px;
  cursor: pointer;
}

.show .menu-view__theme-change {
  transition: transform 0.55s cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s,
    opacity 0.3s ease 0.15s;
  transform: translateX(0px);
  opacity: 1;
}

.menu-view__theme-change:hover .menu-view__theme-change__text {
  transform: none;
}

.menu-view__theme-change .menu-view__theme-change__mask {
  overflow: hidden;
  height: 19px;
  display: inline-flex;
  flex-direction: column;
}

.menu-view__theme-change .menu-view__theme-change__mask span {
  transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  position: relative;
}

.menu-view__theme-change .theme-icon {
  display: inline-block;
  margin-left: 20px;
  position: relative;
  width: 28px;
  height: 28px;
  transition: transform 0.3s ease 0s;
}

.menu-view__theme-change .theme-icon .theme-sunandmoon {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.menu-view__theme-change:hover .theme-sunandmoon {
  transform: rotate(-110deg);
}

.menu-view__theme-change .theme-icon .theme-sun {
  position: absolute;
  width: 100%;
  height: 100%;
}

.theme--light .menu-view__theme-change .theme-icon .theme-sun::after,
.theme--light .menu-view__theme-change .theme-icon .theme-sun::before {
  border-radius: 100em;
  background-color: rgb(18, 18, 18);
}

.menu-view__theme-change .theme-icon .theme-sun::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255);
  transform: rotate(45deg);
}

.menu-view__theme-change .theme-icon .theme-sun::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255);
  transform: rotate(0deg);
}

.menu-view__theme-change .theme-icon .theme-moon {
  position: absolute;
  width: 86%;
  height: 86%;
  top: 7%;
  left: 7%;
  border-radius: 1000px;
  background-color: rgb(18, 18, 18);
  overflow: hidden;
}

.theme--light .menu-view__theme-change .theme-icon .theme-moon {
  background-color: rgb(255, 255, 255);
}

.menu-view__theme-change .theme-icon .theme-moon::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 90%;
  transform: translate(-100%, 100%);
  border-radius: 1000px;
  background-color: rgb(255, 255, 255);
  transition: transform 0.8s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.theme--light .menu-view__theme-change .theme-icon .theme-moon::before {
  transition: transform 1.3s ease 0s;
  transform: translate(35%, -15%);
  background-color: rgb(18, 18, 18);
}

.nav-footer {
  position: fixed;
  left: 50px;
  bottom: 50px;
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s,
    margin 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.nav-footer {
  z-index: 1100;
}

.nav-footer .sessions {
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 700;
  opacity: 0.65;
}

.nav-footer .sessions > span {
  display: inline-block;
  padding-right: 21px;
  position: relative;
}

.nav-footer .sessions > span::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 2px;
  display: block;
  width: 1px;
  height: 9px;
  background-color: rgba(0, 0, 0, 0.4);
}

.nav-footer .sessions > span:last-child::after {
  display: none;
}

.nav-footer__links {
  margin-bottom: 8px;
  display: flex;
}

.nav-footer__links__item {
  position: relative;
  padding-right: 21px;
  font-size: 14px;
  font-weight: 700;
}

.nav-footer__links__item::after {
  content: "";
  position: absolute;
  right: 9px;
  top: 4px;
  display: block;
  width: 1px;
  height: 11px;
  background: rgba(0, 0, 0, 0.2);
}

.nav-footer__links__item a {
  position: relative;
  display: inline-block;
}

.hv-underline {
  position: relative;
  display: inline-block;
  line-height: 1em;
}

.hv-underline::after {
  content: "";
  transition: width 0.2s ease 0s, left 0.2s ease 0s, transform 0.2s ease 0s;
  width: 0px;
  height: calc(1px + 0.1em);
  background-color: rgb(0, 0, 0);
  position: absolute;
  left: 50%;
  bottom: -0.15em;
}

.hv-underline:hover::after,
.underline {
  width: 100%;
  left: 0px;
}

.nav-footer__links__item:last-child {
  padding: 0px;
}

.nav-footer__links__item:last-child::after {
  display: none;
}

.nav-footer .copyright {
  font-size: 12px;
  font-weight: 700;
}
///////////////////////////////////////////////////////////////////////////////////@extend

.menu-view__center {
  display: flex;
  flex-direction: column;
  padding-top: 92px;
  margin-top: 20px;
  width: 149px;
  line-height: 1.54em;
  margin-left: 5%;
}

.menu-view__center .menu-view__center-item > span {
  color: rgba(255, 255, 255, 0.3);
  font-size: 20px;
  font-family: Montserrat;
}
.menu-view__center .menu-view__center-item {
  border-top: 1px solid black;
  margin-top: 25px;
  padding-top: 25px;
}
.menu-view__center .menu-view__center-item.menu-hr1 {
  margin-top: 167px;
}
.menu-view__center .menu-view__center-item.menu-hr2 {
  margin-top: 170px;
}
.MenuLeftItem.Notice {
  display: none;
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .MenuViewMain,
  .MenuViewMain.hide {
    justify-content: flex-start;
  }
  .MenuLeftTitle {
    margin-right: initial;
  }
  .MenuLeftItem {
    margin-bottom: 15px;
  }
  .MenuBox {
    padding: 0 50px;
    margin-top: 110px;
  }
  .MenuLeftTitle {
    font-size: 100px;
  }

  .MenuLeftItem.Notice {
    display: inline;
  }
  .MenuLeftTitle .MenuLeftText::before {
    content: "";
    transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
    bottom: 47px;
    opacity: 0.5;
  }
}

@media all and (max-width: 767px) {
  .MenuLeftTitle .MenuLeftItem:hover .MenuLeftText::before {
    width: 100%;
    left: 0px;
    opacity: 1;
    transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
      opacity 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    color: rgba(255, 81, 13, 0.8);
  }
  .MenuViewMain,
  .MenuViewMain.hide {
    justify-content: flex-start;
  }
  .MenuLeftTitle {
    margin-right: initial;
  }
  .MenuRightText {
    font-size: 14px;
  }
  .MenuRightTitle {
    margin-right: 10px;
    width: 100px;
    margin-left: 12px;
    font-size: 14px;
  }
  .MenuBox {
    padding: 0 25px;
  }
  .MenuLeftTitle {
    font-size: 60px;
  }
  .MenuLeftItem {
    margin-bottom: 40px;
  }

  .MenuLeftItem.Notice {
    display: inline;
  }
  .MenuLeftTitle .MenuLeftText::before {
    content: "";
    transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
    bottom: 15px;
    opacity: 0.5;
  }
}

@media all and (max-width: 400px) {
  .MenuLeftTitle {
    font-size: 30px;
  }
  .MenuLeftTitle .MenuLeftText::before {
    content: "";
    transition: left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
      opacity 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
    bottom: 17px;
    opacity: 0.5;
  }
}

@media all and (max-width: 300px) {
  .MenuLeftTitle {
    font-size: 45px;
  }
}
