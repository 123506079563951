.Project_C_container {
  overflow: hidden;
  position: relative;
  margin-top: 380px;
  .inner {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 0 0 0 261px;
  }
}
