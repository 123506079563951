.Eelementcontainer {
  overflow: hidden;
  margin-top: 400px;
  .con_bx {
    width: 1200px;
    margin: 0 auto;
    .txt {
      overflow: hidden;
      margin-top: 33px;
      color: #808080;
      font-size: 16px;
      font-weight: 500;
      line-height: 38px;
    }
    .icon_bx {
      width: 106px;
      margin: 88px 90px 0 0;
      > img {
      }
    }

    .list_color {
      overflow: hidden;
      margin: 150px 0 0 26px;
      .list {
        overflow: hidden;
        float: left;
        width: 300px;
        height: 250px;
        margin-right: 125px;
        text-align: right;
        .circle1 {
          background: #ffe346;
          overflow: hidden;
          display: block;
          width: 250px;
          height: 250px;
          border-radius: 100%;
        }
        .point_col {
          overflow: hidden;
          margin-top: -160px;
        }
        .circle2 {
          background: #ff3c50;
          overflow: hidden;
          display: block;
          width: 250px;
          height: 250px;
          border-radius: 100%;
        }
      }
      .list02 {
        list-style: none;
        .circle3 {
          background: #1a73ff;
          overflow: hidden;
          display: block;
          width: 250px;
          height: 250px;
          border-radius: 100%;
        }
        .point_col {
          overflow: hidden;
          margin-top: -160px;
          text-align: right;
        }
      }
    }
    .font_txt {
      margin-top: 50px;
    }
  }
}
