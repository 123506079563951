.AboutMiddleContainer {
    background-color: #343537;
    padding: 0 50px;
    .AboutMiddleMain {
        max-width: 1630px;
        width: 100%;
        margin: auto;
        .aboutMiddle-slider {
            padding-bottom: 180px;
            .slider-item {
                width: 100%;
                padding-right: 5px;
                box-sizing: border-box;
                img{
                    width: 100%;
                    max-height: 715px;
                }
              .ImgFont{
                  padding-top: 50px;
              }
            }
            .slick-arrow{
                top: 95%;
                background: transparent;
                color:#fff;
                &:before{
                    color: transparent;
                }
                &.slick-prev{
                    left: initial;
                    right: 50px;
                    z-index: 9999;
                  
                }
                &.slick-next{
                    right: 0;
                }
            }
        }
    }

    .FirstContainer {
        padding: 200px 0px 250px 0px;
        display: flex;
        align-items: center;
    }

    .TextBox {
        max-width: 1630px;
        width: 100%;
        margin: auto;
        color: #ffffff;
        font-family: Montserrat;
        flex: 1;
    }
    .FirstImg {
        flex: 1;
    }
    .Value {
        font-size: 16px;
        line-height: 1;
        padding-bottom: 40px;
    }
    .Title {
        font-size: 60px;
        line-height: 1;
        padding-bottom: 35px;
    }
    .SubText {
        font-size: 18px;
        line-height: 1.94;
        text-align: left;
        margin-bottom: 10px;
    }

    .FirstAboutImg {
        width: 100%;
        height: auto;
    }

    .ImgFont {
        font-size: 16px;
        font-family: Montserrat;
        color: #ffffff;
    }

    .MainText {
        font-size: 60px;
        font-family: Montserrat;
        color: #ffffff;
        line-height: 1.2;
        text-align: center;
        font-weight: 200;
        padding-bottom: 250px;
        span {
            border-bottom: 3px solid #fff;
            font-weight: 450;
        }
    }

    .ImgFontArrow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 230px;
    }

    .AllArrow {
        width: 85px;
        display: flex;
        justify-content: center;
        justify-content: space-between;
    }
}

@media all and (max-width: 767px) {
    .AboutMiddleContainer {
    }
}
@media all and (max-width:500px) {
    .AboutMiddleContainer {
        .AboutMiddleMain {
            .FontBox {
                .RightText {
                    font-size: 30px;
                }
                .LeftTextBox {
                    .LeftText {
                        font-size: 15px;
                    }
                }
            }
        }
        
        .slick-arrow{
            top: 90% !important;
            img{
                width: 10px;
                height: 10px;
            }
            &.slick-prev{
                right: 25px !important; 
            }
            &.slick-next{

            }
        }
        .FirstContainer {
            flex-direction: column;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .ImgFontArrow {
            margin-bottom: 50px;
        }
        .Value {
            font-size: 10px;
        }
        .Title {
            font-size: 25px;
        }
        .SubText {
            font-size: 12px;
        }
        .MainText {
            font-size: 25px;
            padding-bottom: 50px;
        }
        .ImgFont{
            font-size: 10px;
        }
    }
}
