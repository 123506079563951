.Pgift_container {
  overflow: hidden;
  .Pgift_cont_1 {
    overflow: hidden;
    width: 100%;
  }
  .Pgift_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn14.png) left
      top repeat-x;
    .Pgift_bx_1 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn52.png)
        center top no-repeat;
    }
  }
  .Pgift_cont_3 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn15.png) left
      top repeat-x;
    .Pgift_bx_2 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn53.png)
        center top no-repeat;
    }
  }
  .Pgift_tit {
    overflow: hidden;
    width: 960px;
    height: 202px;
    margin: 0 auto;
    padding-top: 49px;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
