.Pitem_container {
  overflow: hidden;
  .Pitem_cont_1 {
    overflow: hidden;
    width: 100%;
  }
  .Pitem_cont_2 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: #dfe2e6
      url(../../../../../_asset/images/Prorenata/img_prn44.png) center top
      no-repeat;
  }
  .Pitem_cont_3 {
    overflow: hidden;
    width: 100%;
    height: 998px;
    border-top: 2px solid #fff;
    background: url(../../../../../_asset/images/Prorenata/bg_prn13.png) center
      top no-repeat;
    background-size: cover;
    .Pitem_bx_1 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: url(../../../../../_asset/images/Prorenata/img_prn45.png)
        center top no-repeat;
    }
  }
  .Pitem_cont_4 {
    overflow: hidden;
    width: 100%;
    border-top: 2px solid #fff;
  }
  .Pitem_cont_5 {
    overflow: hidden;
    width: 100%;
    border-top: 2px solid #fff;
  }
  .Pitem_cont_6 {
    overflow: hidden;
    width: 100%;
    border-top: 2px solid #fff;
  }
  .Pitem_tit {
    overflow: hidden;
    width: 960px;
    height: 202px;
    margin: 0 auto;
    padding-top: 49px;
    background: #fff;
    text-align: right;
    .tit {
      font-size: 12px;
    }
  }
}
