.section2 {
    --heightSaleSection: 900px;
    --fsTitleHerosection: 50px;
    --fsDetailHerosection: 22px;
    --imgWidthHeroSection: 600px;
    height: var(--heightHeroSection);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: #f5f8fc;
    gap: 40px;

    &-container {
        margin: 0 auto;
        width: 1280px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        @media screen and (max-width: 1279px) {
            width: 100%;
        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        .content {
            width: 50%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: var(--fsTitleHerosection);
                line-height: 1.2;
                font-weight: bold;

                .blue-text {
                    color: #00a6fc;
                }
            }

            &-detail {
                font-size: var(--fsDetailHerosection);
                padding-top: 30px;
                color: #333333;
                line-height: 1.4;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .img {
            object-fit: contain;
            width: 50%;

            img {
                width: var(--imgWidthHeroSection);
                object-fit: contain;

                @media screen and (max-width: 1279px) {
                    width: 100%;
                }
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                padding-top: 30px;
            }
        }
    }

    @media screen and (max-width: 1079px) {
        --heightHeroSection: 700px;
        --fsTitleHerosection: 32px;
        --fsDetailHerosection: 18px;
    }

    @media screen and (max-width: 767px) {
        --heightHeroSection: 650px;
        --fsTitleHerosection: 24px;
        --fsDetailHerosection: 16px;
    }
}