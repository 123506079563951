.brandcontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-top: -40px;
  .cnt_top {
    position: relative;
    z-index: 10;
    max-width: 1226px;
    width: 100%;
    margin: 254px auto 0;
    @media screen and (max-width: 480px) {
      > div:last-child {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .brand_img_01 {
    > img {
      width: 335px;
      height: 707px;
      border-radius: 37px;
      box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
    }
  }
  .brand_img_02 {
    margin: 272px 0 0 112px;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 480px) {
      margin: 20px 0px 0px 0px;
    }
    > img {
      width: 335px;
      height: 707px;
      border-radius: 37px;
      box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
    }
  }
  .cnt {
    max-width: 1226px;
    width: 100%;
    margin: 313px auto 0;
    padding-bottom: 253px;
    @media screen and (max-width: 480px) {
      margin-top: 250px;
      display: flex;
      flex-direction: column;
      > div:nth-child(1) {
        position: absolute;
        bottom: 12%;
        font-size: 30px;
        letter-spacing: 20px;
      }
      > div:nth-child(2) {
        position: absolute;
        bottom: 8%;
        font-size: 12px;
      }
      .blUjSN {
        position: relative;
        left: -3%;
      }
    }
    .list_img {
      position: absolute;
      top: -786px;
      left: 0;
      > img {
        width: 305px;
        height: 1625px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
    .list_img02 {
      position: absolute;
      top: 125px;
      left: 360px;
      > img {
        width: 312px;
        height: 976px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
    .list_img03 {
      position: absolute;
      top: 645px;
      left: 715px;
      > img {
        width: 312px;
        height: 575px;
        box-shadow: 33px 30px 79px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
