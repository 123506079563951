.LinkClickBlackContainer{
    

    .LinkClickBlackText{
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #222222;
        a{
            color: #222222; 
        }

        &:hover{
            // heartbeat Animation
            -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
        }

        
    }
    

    
}

.LinkClickContainer{
    .LinkClickText{
        z-index: 40;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #ffffff;
        color: #ffffff;
        &:hover{
            // heartbeat Animation
            -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
        }
    }
}

//Tablet | Mobile 분기점
@media all and (max-width:767px) {
    .LinkClickBlackContainer{
        .LinkClickBlackText{
            font-size: 14px;
        }
    }
    
    .LinkClickContainer{
        .LinkClickText{
            font-size: 14px;
        }
    }
  }


@-webkit-keyframes pulsate-fwd {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
@keyframes pulsate-fwd {
0% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}
  
  