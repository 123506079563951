.AboutBotContainer {
  .AboutBotMain {
    margin: 0 50px;
  }
  .TopBox {
    max-width: 1630px;
    margin: auto;
  }
  .BotFirstImg {
    width: 100%;
    max-height: 715px;
    object-fit: cover;
    object-position: bottom;
  }
  .TextBox {
    max-width: 1630px;
    width: 100%;
    margin: auto;
    color: #ffffff;
    font-family: Montserrat;
    padding-top: 200px;
  }

  .LastTextBox {
    max-width: 1630px;
    width: 100%;
    margin: auto;
    color: #ffffff;
    font-family: Montserrat;
    margin-top: 310px;
  }

  .LastTextBox {
    max-width: 1630px;
    width: 100%;
    margin: auto;
    color: #ffffff;
    font-family: Montserrat;
    margin-top: 310px;
  }

  .Value {
    font-size: 16px;
    padding-bottom: 32px;
  }
  .Title {
    font-size: 60px;
    padding-bottom: 35px;
  }
  .SubText {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 150px;
  }

  .LastSubText {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.94;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 200px;
  }

  .ImgBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ShapeBox {
    width: 50%;
    position: absolute;
    top: -40%;
    right: 0px;
    display: flex;
    justify-content: flex-end;
  }
  .RightImgBox {
    flex: 1;
    margin-top: 23%;
    position: relative;
    z-index: 9999;
    margin-left: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .LeftImgBox {
    flex: 1;
    img {
      width: 100%;
      height: auto;
    }
  }
  .ImgText {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    margin-top: 30px;
  }
  .ImgBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1630px;
    width: 100%;
  }

  .RightTextBot {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1630px;
    width: 100%;
    margin: auto;
  }
  .shape {
    z-index: -1;
  }
  .LastImgBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1630px;
    width: 100%;
    margin: auto;
  }
  .TextBoxMain {
    display: flex;
    flex-direction: row;
  }
  .FlexBox {
    flex: 1;
  }
  .ContentsSilder {
    margin-bottom: 300px;
  }
}

@media all and (max-width: 767px) {
  .AboutBotContainer {
  }
}

@media all and (max-width:500px) {
  .AboutBotContainer {
    .ContentsSilder {
      margin-bottom: 50px;
    }
    .TextBox {
      padding-top: 50px;
    }
    .AboutBotMain {
      margin: 0;
      padding: 0 50px;
      box-sizing: border-box;
      .FontBox {
        .RightText {
          font-size: 30px;
        }
        .LeftTextBox {
          .LeftText {
            font-size: 15px;
          }
        }
      }
    }
    .ImgBox {
      .ImgText {
        font-size: 10px;
      }
    }
    .FirstContainer {
      flex-direction: column;
    }
    .Value {
      font-size: 10px;
    }
    .Title {
      font-size: 25px;
    }
    .SubText {
      font-size: 12px;
    }
    .MainText {
      font-size: 25px;
    }
    .TextBoxMain {
      .FlexBox {
        flex: initial;
        .LastSubText {
          font-size: 10px;
          margin-bottom: 50px;
        }
      }
    }
  }
}
