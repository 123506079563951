.swal2-title,
.swal2-content {
    font-family: Montserrat;
}
.EnterProjectAll {
    .OtherTitle {
        margin-bottom: 10px;
    }
    .fileAttachmentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid white;
        padding-bottom: 20px;
        height: 65px;
        font-family: Montserrat;
        box-sizing: border-box;
        .inputFilePlaceholder {
            font-size: 2vw;
            color: white;
        }
        .inputFile {
            display: none;
        }
        .inputFileButton {
            font-family: Montserrat;
            font-size: 20px;
            color: white;
        }
    }
    .rightWrapper {
        width: 100%;
    }
    .SmallElement {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 300;
        color: #ff641f;
    }
    .hrcss {
        border: 1px solid white;
    }
    .secondhrcss {
        margin-top: 48px;
        border: 1px solid white;
        margin-bottom: 10px;
    }

    .EnterProjectTitle {
        padding-top: 130px;
        font-family: Montserrat;
        font-size: 60px;
        color: #ffffff;
        max-width: 1630px;
        width: 100%;
        margin: auto;
        margin-bottom: 70px;
    }

    .InformationFillinBox {
        display: flex;
        margin-bottom: 20px;
        max-width: 1630px;
        width: 100%;
        justify-content: space-between;
        .leftWrapper {
            padding-right: 95px;
        }
        .rightWrapper {
            padding-left: 95px;
        }
    }

    .ProjectNameBox {
        margin-bottom: 90px;
    }

    .RequiredElements {
        display: flex;
        margin-bottom: 34px;
    }
    .OtherRequiredElements {
        display: flex;
        margin-bottom: 34px;
    }

    .NameTitle {
        font-family: Montserrat;
        font-size: 17.5px;
        font-weight: 600;
        color: #6c6c6c;
    }

    .Emphasis {
        font-family: Montserrat;
        color: #ff641f;
        margin-left: 5px;
    }
    .ComentEmphasis {
        font-size: 13px;
        font-family: Montserrat;
        color: #ffffff;
        margin-left: 15px;
        font-weight: 300;
        margin-top: 3px;
    }

    .SelectTitle {
        max-height: 100px;
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
        input {
            height: 40px;
            width: 100%;
            background-color: #333;
            font-size: 30px;
            font-weight: 500;
            font-family: Montserrat;
            border: none;
            border-bottom: 2px solid white;
            padding-bottom: 20px;
            background-color: transparent;
            color: #ffffff;
            &::placeholder {
                color: white;
            }
            &:focus {
                outline: initial;
            }
        }
    }
    .DetailTitle {
        max-width: 596px;
        max-height: 100px;
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
    }

    .OtherSelectTitle {
        max-width: 596px;
        max-height: 100px;
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
        display: flex;
        input {
            height: 40px;
            width: 100%;
            padding-left: 14px;
            background-color: #333;
            font-size: 30px;
            font-weight: 500;
            font-family: Montserrat;
            border: none;
            background-color: transparent;
            color: #ffffff;

            &:focus {
                outline: initial;
            }
        }
    }
    .DetailTitleInput {
        max-width: 596px;
        max-height: 100px;
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
        display: flex;
        input {
            height: 40px;
            width: 100%;
            padding-left: 14px;
            background-color: #333;
            font-size: 30px;
            font-weight: 500;
            font-family: Montserrat;
            border: none;
            background-color: transparent;
            color: #ffffff;

            &:focus {
                outline: initial;
            }
        }
    }

    .DownSelectTitleBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 55px;
    }
    .SelectSmallTitle {
        font-family: Montserrat;
        font-size: 20px;
        color: #6c6c6c;
        font-weight: 500;
        padding: 0px 0px 0px 14px;
        display: flex;
        justify-content: flex-end;
    }

    .LineAll {
        border: 1px solid #6c6c6c;
        text-align: left;
        margin-left: 0px;
        margin-top: 17px;
    }
    .ButtonLineAll {
        border: 1px solid #6c6c6c;
        text-align: left;
        margin-left: 0px;
        margin-top: 3px;
    }

    .DownSelectTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .DownOtherTitle {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 12px;
        height: 55px;
    }
    .CheckArrow {
        padding-top: 18px;
    }

    .MuiAccordionSummary-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 18px;
    }
    .MuiAccordionSummary-root {
        background-color: #222;
        padding: 0;
    }
    .MuiTypography-root {
        font-family: Montserrat;
        font-size: 30px;
        color: #6c6c6c;
        font-weight: 500;
    }
    .MuiAccordionDetails-root {
        background-color: #222222;
        color: #6c6c6c;
        line-height: 1.8;
    }
}
@media all and (max-width:1200px) {
    .EnterProjectAll {
        .leftWrapper {
            padding-right: 30px !important;
        }
        .rightWrapper {
            padding-left: 30px !important;
        }
    }
}

@media all and (max-width:960px) {
    .EnterProjectAll {
        .fileAttachmentWrapper {
            .inputFileButton {
                font-size: 1.5vw;
            }
        }
        .leftWrapper {
            padding-right: 0 !important;
        }
        .rightWrapper {
            padding-left: 0 !important;
        }
    }
}

@media all and (max-width: 767px) {
    .EnterProjectAll {
        .EnterProjectTitle {
            font-family: Montserrat;
            font-size: 3.5vw;
            color: #ffffff;
            max-width: 1630px;
            width: 100%;
            margin: auto;
            margin-bottom: 8vw;
            padding-top: 10vw;
        }

        .LeftInformationBox {
            margin: auto;
        }

        .ProjectNameBox {
            margin-bottom: 45px;
        }

        .RequiredElements {
            display: flex;
            margin-bottom: 14px;
        }
        .OtherRequiredElements {
            display: flex;
            margin-bottom: 14px;
        }

        .NameTitle {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            color: #6c6c6c;
        }

        .Emphasis {
            font-family: Montserrat;
            color: #ff641f;
            margin-left: 5px;
        }
        .ComentEmphasis {
            font-size: 9px;
            font-family: Montserrat;
            color: #ffffff;
            margin-left: 5px;
            font-weight: 300;
            margin-top: 0px;
        }

        .DetailTitle {
            max-height: 100px;
            font-family: Montserrat;
            font-size: 18px;
            color: #6c6c6c;
            font-weight: 500;
        }

        .OtherSelectTitle {
            max-width: 676px;
            max-height: 100px;
            font-family: Montserrat;
            font-size: 18px;
            color: #6c6c6c;
            font-weight: 500;
            display: flex;

            input {
                height: 40px;
                width: 100%;
                padding-left: 0px;
                background-color: white;
                font-size: 18px;
                font-weight: 500;
                font-family: Montserrat;
                border: none;
                background-color: transparent;
                color: #ffffff;

                &:focus {
                    outline: initial;
                }
            }
        }
        .DetailTitleInput {
            max-width: 596px;
            max-height: 100px;
            font-family: Montserrat;
            font-size: 18px;
            color: #6c6c6c;
            font-weight: 500;
            display: flex;
            input {
                height: 40px;
                width: 100%;
                padding-left: 0px;
                background-color: #333;
                font-size: 18px;
                font-weight: 500;
                font-family: Montserrat;
                border: none;
                background-color: transparent;
                color: #ffffff;

                &:focus {
                    outline: initial;
                }
            }
        }

        .DownSelectTitleBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
        }
        .SelectSmallTitle {
            font-family: Montserrat;
            font-size: 14px;
            color: #6c6c6c;
            font-weight: 500;
            padding: 0px;
            display: flex;
            justify-content: flex-end;
        }

        .LineAll {
            max-width: 282px;
            // width: 100%;
            border: 1px solid #6c6c6c;
            text-align: left;
            margin-left: 0px;
            margin-top: 10px;
        }
        .ButtonLineAll {
            border: 1px solid #6c6c6c;
            text-align: left;
            margin-left: 0px;
            margin-top: 0px;
        }
        .RightInformationBox {
            max-width: 100%;
        }

        .DownSelectTitle {
            width: 100%;
            max-width: 767px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .DownOtherTitle {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            justify-content: initial;
            padding-right: 0px;
        }
        .CheckArrow {
            padding-top: 0px;
        }
    }
}
@media all and (max-width: 400px) {
    .EnterProjectAll{
        .fileAttachmentWrapper{
            padding-bottom: 0;
            height: 48px;
        }
        .SelectTitle{
            input{
                padding-bottom: 0;
            }
        }
    }
}
