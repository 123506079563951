.con_premium_container {
  padding: 50px 0;
  .con_premium {
    position: relative;
    .brand_film {
      overflow: hidden;
      position: relative;
    }
  }
}

.container {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
}
