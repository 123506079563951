.full-screen-square {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 99999;
    display: grid;
    place-items: center;
    background-color: #000;
    overflow-y: auto;
    img {
        width: 100%;
    }
    .back-container {
        position: fixed;
        top: 20px;
        right: 40px;
        z-index: 10000000;
        .back-button {
            color: #F1F1F1;
            font-size: 35px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}
