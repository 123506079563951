.Emusiccontainer {
  position: relative;
  margin-top: 280px;
  .background {
    overflow: hidden;
    position: relative;
    margin-top: 97px;
    height: 1572px;
    background: url(../../../../../_asset/images/emart/bg_music.png) center top
      no-repeat;
    background-size: 1920px 1572px;
    .screen_list01 {
      > img {
        max-width: 100%;
        border-radius: 40px;
        box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
      }
    }
    .screen_list02 {
      margin: 300px 112px 0;
      > img {
        max-width: 100%;
        border-radius: 40px;
        box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
      }
    }
    .screen_list03 {
      margin-top: 600px;
      > img {
        max-width: 100%;
        border-radius: 40px;
        box-shadow: 32px 16px 40px rgba(34, 34, 34, 0.4);
      }
    }
  }
}
