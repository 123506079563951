.Equickcontainer {
  width: 1200px;
  margin: 690px auto 0;
  .subtitle {
    overflow: hidden;
    margin-top: 30px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }
  .quick_list01 {
    display: inline-block;
    margin-right: 75px;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
  .quick_list02 {
    display: inline-block;
    margin-right: 75px;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
  .quick_list03 {
    display: inline-block;
    border-radius: 40px;
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
  }
}
