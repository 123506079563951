
.tc {
    word-break: keep-all;
    z-index: 1;
    background-color: white;
    width: 100%;
    overflow: hidden;
    font-family: PretendardMedium;
    .header {
        padding: 0 15px;
        height: 80px;
        background-color: white;
        display: flex;
        justify-content: end;
        align-items: center;
        position: relative;
        &__logoCenter {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &__btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__language {
            width: 151px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border: 1px solid ;
            border-radius: 5px;
            cursor: pointer;
            margin-right: 10px;
        }
        &__login {
            border-radius: 5px;
            cursor: pointer;
            background-color: #fe5a60;
            color: white;
            text-align: center;
            width: 151px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .banner1 {
        height: auto;
        width: 100%;
        position: relative;
        &__arrow {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 70%;
    left: 50%;
    width: 32%;
    transform: translateX(-50%);
        }
        &__bg {
            // position: absolute;
            // left: 0;
            // right: 0;
            // top: 0;
            // bottom: 0;
            // object-fit:contain;
            width: 100%;
            // z-index: 0;
        }
        &__text {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
        &__title {
            font-size: 60px;
            font-weight: bold;
            font-family: PretendardBold;
            letter-spacing: normal;
            text-align: center;
            color: #222;
            z-index: 1 !important;
            margin-top: 100px;
            margin-bottom: 20px;
            line-height: 1;
        }
        &__text1 {
            line-height: 0;
        }
    }

    .info {
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-family: PretendardBold;
        text-align: center;
    }
    .banner2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 110px;
        padding-bottom: 130px;
        &__title {
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            color: #222;
            margin-bottom: 60px;
        }
        &__body {
            display: flex;
            gap: 18px
        }
    }
    .banner3 {
        color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        &__bg {
            width: 100%;
        }
        &__content {
            position: absolute;
            width: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            display: flex;
        flex-direction: column;
        align-items: center;
        }
        &__title {
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            color: white;
            margin-bottom: 30px;
            margin-top: 100px;
        }
        &__sub {
            font-size: 20px;
            font-weight: normal;
            margin-bottom: 30px;
            margin-top: 0;
        }
        &__sub1 {
            font-size: 20px;
            font-weight: normal;
            margin-top: 0;
            margin-bottom: 5px;
            @media screen and (max-width: 767px) {
                font-size: 16px;
            }
        }
        &__sub2 {
            font-size: 20px;
            font-weight: normal;
            margin-top: 0;
            margin-bottom: 5px;
            @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
        }
        &__number {
            margin-bottom: 30px;
            width: 48px;
            height: 48px;
        }
        &__number2 {
            margin-bottom: 20px;
            height: 40px;
        }
    }

    .banner4 {
        color: black;
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 120px;
        &__title {
            padding-top: 110px;
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            color: black;
            margin-bottom: 30px;
            margin-top: 0px;
        }
        &__number {
            margin-bottom: 30px;
            width: 48px;
            height: 48px;
        }
        &__number2 {
            margin-bottom: 20px;
            height: 40px;
        }
        &__sub {
            font-size: 20px;
            font-weight: normal;
            margin-bottom: 30px;
            margin-top: 0;
        }
        &__sub1 {
            font-size: 20px;
            font-weight: normal;
            margin-top: 0;
            margin-bottom: 5px;
        }
        &__sub2 {
            font-size: 20px;
            font-weight: normal;
            margin-top: 0;
            margin-bottom: 5px;
        }
    }
    .banner5 {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        color: black;
        &__bg {
            width: 100%;
        }
        &__content {
            width: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            display: flex;
        flex-direction: column;
        align-items: center;
        }
        &__title1 {
            margin-top: 300px;
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            line-height: 0;
            margin-bottom: 0;
        }
        &__title2 {
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            line-height: 1;
        }
        &__desc {
            font-size: 29.5px;
            font-weight: 600;margin-bottom: 10px;
            margin-top: 10px;
        }
        &__btn {
            margin-top: 40px;
            background-color: #111;
            width: 220px;height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border: 1px solid #222;
            font-weight: bold;
            font-family: PretendardBold;
            font-size: 20px;
            cursor: pointer;

        }
    }
    .banner6 {
        color: black;
        background-color: white;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 110px;
        padding-bottom: 120px;
        &__title {
            font-size: 50px;
            font-weight: bold;
            font-family: PretendardBold;
            color: black;
            margin-bottom: 30px;
            margin-top: 0px;
        }
        &__content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
        }
    }
    .footer {
        background-color: #F8F8F8;
        color: #a0a0a0;
        padding: 50px 15px;
        .header {
            background-color: #F8F8F8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
        }
        &__logo {
            width: 74px;
            height: 34px;
        }
        &__icon {
            img {

                width: 18px;
            }
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: start;
            color: #111;
            font-size: 12px;
            gap: 9px;
        }
        &__link {
            display: flex;
            align-items: start;
            flex-direction: column;
            gap: 8px;
        }
        &__address {
            font-size: 15px;
            font-weight: normal;
        }
    }

    @media screen and (max-width: 800px) {
        .banner1,.banner2,.banner3,.banner4,.banner5,.banner6 {
            &__title {
                font-size: 28px;
                margin-top: 10px;
            }

        }
        .header {
            &__btn {
                display: none;
            }
        }
        .banner1 {
            background-color: #FFBC61;
            padding-top: 70px;
            &__text {
                width: 98%;
                padding-top: 10px;
            }
            &__arrow{
                img {
                    transform: translateY(20px);
                    width: 15px;
                    height: 15px;
                }
            }
            &__title {
                margin-top: 10px;
            }
        }
        .info {
            font-size: 14px;
            // padding: 15px;
        }
        .banner2 {
            &__title {
                margin-bottom: 20px;
            }
            padding: 15px;
            padding-top: 50px;
            padding-bottom: 50px;
            &__body {
                flex-direction: column;
                img {
                    width: 100%;
                }
            }
        }
        .banner3 {
            max-height: 1050px;
            overflow: hidden;
            &__title {
                margin-top: 50px;
            }
            .img_body {
                width: 100%;
            }
            &__content {
                width: 95%;
            }
        }
        .banner4 {
            padding: 15px;
            &__title {
                padding-top: 35px;
            }
            .img_body {
                width: 100%;
            }
            &__sub {
                font-size: 24px;
            }
            &__sub1 {
                font-size: 24px;
            }
            &__sub2 {
                font-size: 16px;
            }
        }

        .banner5 {
            padding: 15px;
            &__content {
                position: unset;
                width: 100%;
                align-items: center;
                justify-content: center;
                transform: none;
            }
            &__title1 {
                font-size: 24px;margin-top: 30px;
            }
            &__title2 {
                font-size: 24px;
            }
            &__desc {
                font-size: 16px;
            }
            .img_body {
            display: none;
            }
            
        }
        .banner6 {
            padding: 15px;
            &__content {
                flex-direction: column;
                img {
                    width: 100%;
                }
            }
        }
        .footer {
            .header {
                flex-direction: column;
            }
            &__link {
                display: none;
            }
        }

    
    }
    
}