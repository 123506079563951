.section7 {
  // --height: au;
  // height: var(--height);

  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;

  &-container {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      font-size: 50px;
      font-weight: bold;

      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    .detail {
      font-size: 22px;
      padding-top: 25px;
      line-height: 1.4;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .list {
      margin-top: 50px;

      &-details {
        --col-gap: 2.4rem;
        --row-gap: 2.4rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 calc(var(--col-gap) / 2 * -1) calc(var(--row-gap) * -1);
        align-items: center;

        @media screen and (max-width: 772px) {
          --col-gap: 12px;
          --row-gap: 12px;
        }
        @media screen and (max-width: 600px) {
          --row-gap: 0px;
        }
      }

      &-inner {
        width: 180px;
        height: 180px;
        border-radius: 100rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 772px) {
          height: 130px;
        }
        @media screen and (max-width: 500px) {
          height: 100px;
        }

        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
          //  border-radius: 99px;
        }

        @media screen and (max-width: 772px) {
          width: 100%;
        }
      }

      //increase size
      .image-1 {
        img {
          width: 90%;
          height: 90%;
        }
      }

      .image-8 {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .image-12 {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .image-4 {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-10 {
        img {
          width: 85%;
          height: 85%;
        }
      }
      .image-15 {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-16 {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .image-18 {
        img {
          width: 100%;
          height: 100%;
        }
      }

      //decrease size
      .image-2 {
        img {
          width: 60%;
          height: 60%;
        }
      }

      .image-6 {
        img {
          width: 65%;
          height: 65%;
        }
      }

      .image-11 {
        img {
          width: 65%;
          height: 65%;
        }
      }
      .image-13 {
        img {
          width: 72%;
          height: 72%;
        }
      }
      .image-17 {
        img {
          width: 73%;
          height: 73%;
        }
      }

      &-item {
        padding: 0 calc(var(--col-gap) / 2);
        margin-bottom: var(--row-gap);
        @media screen and (max-width: 772px) {
          width: 30%;
        }
      }
    }
  }

  // @media screen and (max-width: 1329px) {
  //   --height: 1100px;
  // }
  // @media screen and (max-width: 1111px) {
  //   --height: 1300px;
  // }
  // @media screen and (max-width: 893px) {
  //   --height: 1500px;
  // }

  // @media screen and (max-width: 772px) {
  //   --height: 2200px;
  // }
}
