.ContentsSliderContainer{
    .ContentsSliderMain{
        max-width: 1640px;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;
    }
    .ContentsListBox{
        margin-right: 50px;
    }
    .ListImgBox{
        width: 450px;
        height: 558px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .ListImg{
        object-fit: cover;
        object-position: center;
    }
    .ListTextBox{
        font-family: Montserrat;
        color: #ffffff;
        text-align: left;
    }
    .ListTitle{
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: normal;
        margin-top: 49px;
        margin-bottom: 28px;
    }
    .ListText{
        font-size: 15px;
        font-weight: 300;
        line-height: 1.67;
        letter-spacing: normal;
        max-width: 372px;
        width: 100%;
    }
}
@media screen and (max-width:500px) {
    .ContentsSliderContainer{
        .ContentsListBox{
            width: 100%;
        }
        .ListImgBox{
            width: 100%;
            height: auto;
        }
        .ListTitle{
            font-size: 12px;
        }
        .ListText{
            font-size: 9px;
        }
    }
}