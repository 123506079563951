.interveiwcontainer {
  overflow: hidden;
  position: relative;
  z-index: 10;
  max-width: 1226px;
  width: 100%;
  height: auto;
  margin: 0 auto 0;
  @media screen and (max-width: 480px) {
    .bwwwHa {
      margin-top: 50px;
      font-size: 35px;
      letter-spacing: 15px;
      transition: none;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 978px) {
    .bwwwHa {
      margin-top: 50px;
    }
  }
  .interview_block {
    overflow: hidden;
    margin-top: 265px;
    text-align: center;
    .contents_01 {
      font-size: 30px;
      color: #fff;
    }
    .contents_02 {
      position: relative;
      margin: 45px 0 605px;
      > img {
        max-width: 1222px;
        max-height: 375px;
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 150px;
      }
    }
    .contents_Wrap {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 980px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .contents_03 {
        flex: 1;
        max-width: 415px;
        width: 100%;
        height: 415px;
        border-radius: 50%;
        background: #fff;
        line-height: 415px;
        color: #161616;
        .contents_title {
          font-size: 30px;
          font-weight: 900;
        }
      }
      .contents_04 {
        flex: 1;
        text-align: left;
        max-width: 573px;
        width: 100%;
        margin-top: 117px;
        color: #fff;
        font-size: 16px;
        line-height: 2;
      }
    }
  }
}
