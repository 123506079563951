.Kcover_container {
  overflow: hidden;
  height: 1080px;
  background: #fff url(../../../../../_asset/images/Kaola/bg_cover.png) center
    380px repeat-x;
  .Kcover_cont {
    overflow: hidden;
    position: relative;
    width: 1080px;
    height: 100%;
    margin: 0 auto;
    .Kcover_cont_bx {
      position: relative;
      width: 1080px;
      height: 100%;
      .Kcover_txt1 {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 91px;
      }
      .Kcover_txt2 {
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 92px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .Kcover_container {
    .Kcover_cont {
      max-width: 1080px;
      width: 100%;
      &_bx {
        position: relative;
        max-width: 1080px;
        width: 100%;
        height: 100%;
        > div > img {
          max-width: 630px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Kcover_container {
    .Kcover_cont {
      max-width: 1080px;
      width: 100%;
      &_bx {
        position: relative;
        max-width: 1080px;
        width: 100%;
        height: 100%;
        > div > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
