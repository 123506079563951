.ThirdPage {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  .ThirdText {
    display: flex;
    font-size: 250px;
    font-weight: 900;
    align-items: center;
    color: #ffffff;
  }
  .Link {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 155px;
    z-index: 10;
  }
  .BackImg {
    max-width: 1950px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .ThirdPage {
    .Link {
      padding-bottom: 130px;
    }
  }
}

@media all and (max-width: 767px) {
  .ThirdPage {
    .Link {
      padding-bottom: 120px;
    }
    .BackImg {
      max-width: 1950px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
