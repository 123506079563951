  .topzone__header {
      --widthLogo: 145px;
      padding: 0 15px;
      height: 80px;
      background-color: #ffffff;
      display: flex;
      justify-content: end;
      align-items: center;
      position: relative;

      &__logo {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          @media screen and (max-width: 1079px) {
              left: 30px;
              transform: translate(0, -50%);
          }
      }

      &__logoCenter {

          width: var(--widthLogo);
      }

      .topzone__header__mobile__button {
          display: none;
          cursor: pointer;

          @media screen and (max-width: 767px) {
              display: block;
              padding-right: 20px;
          }
      }

      &__btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;

          @media screen and (max-width: 767px) {
              display: none;
          }
      }

      &__language {
          width: 151px;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border: 1px solid;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 10px;
          background-color: #00a6fc;
          color: #fff;

          img {
              width: 18px;
          }
      }

      &__login {
          border-radius: 5px;
          cursor: pointer;
          background-color: #00d794;
          color: white;
          text-align: center;
          width: 151px;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
      }
  }

  .aside {
      //    display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      transform: translateX(100%);
      transition: 300ms;
      background-color: #111;

      .close__button {
          padding: 20px;
      }

      .topzone__aside__btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 50px;

          .topzone__header__language {
              margin-right: 0;
          }

          .topzone__header__login {
              margin-top: 20px;
          }
      }
  }

  .aside__open {
      transform: translateX(0);
      transition: 300ms;
  }