@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.FirstPage {
  position: relative;
  max-width: 1630px;
  margin: auto;
  .uix-always-thinks {
    font-family: Montserrat;
    font-size: 3.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #d2d2d2;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    right: 15%;

    span {
      border-bottom: 3px solid #d2d2d2;
    }
  }
  .X,
  .I,
  .U {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #e9e9e9;
    font-family: Montserrat;
    font-size: 40vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.11;
    letter-spacing: -40px;
    position: absolute;
  }
  .U {
    left: 150px;
  }
  .I {
    left: 150px;
    bottom: 100px;
  }
  .X {
    bottom: 50px;
    right: 300px;
  }
  .main-slider__header {
    display: flex;
    justify-content: center;
    background-color: #1a1a19;
    max-width: 1630px;
    padding: 0 30px;
    width: 100%;
    left: 100%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 50;
  }
  .header2 {
    z-index: 20;
  }

  .BackImg {
    background-color: #1a1a19;
    max-width: 1920px;
    width: 80%;
    margin: auto;
    position: fixed;
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
    img {
      max-width: 1241px;
      width: 100%;
    }
  }

  .FirstPageTitle {
    // color: #FFFFFF;
    // font-family: Montserrat;
    // font-weight: 300;
    // font-size: 50.5px;
    // z-index: 20;
    // user-select: none;
    // margin: 265px 0px 0px 350px;
    // text-decoration: underline #fff 1px;
    // text-underline-position: under;
    // height: 1px;
    // position: absolute; left: 40%; top: 60%;
    // transform: translate(-50%, -50%); text-align: center;

    // Slide-in-left
    -webkit-animation: fade-in-left 1.3s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: fade-in-left 1.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .CrossGrid {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1000;
  }
  .Link {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 155px;
  }
}
@media screen and (min-width: 1920px) {
  .X,
  .I,
  .U {
    font-size: 900px !important;
  }
  .uix-always-thinks {
    font-size: 70px !important;
  }
}
@media (max-width: 1270px) {
  .FirstPage {
    .FirstPageTitle {
      font-size: 40px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width: 1024px) {
  .FirstPage {
    .main-slider__header {
      margin-top: 140px;
      padding: 0 50px;
    }
    .X,
    .I,
    .U {
      font-size: 32vw;
    }
    .uix-always-thinks {
      font-size: 5vw;
      top: 40%;
    }
    .U {
      left: 150px;
      top: 40%;
      transform: translateY(-50%);
    }
    .I {
      left: 150px;
      bottom: 25%;
      transform: translateY(-50%);
    }
    .X {
      bottom: 25%;
      transform: translate(-50%, -50%);
      right: 15%;
    }
    .BackImg {
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media all and (max-width: 767px) {
  .FirstPage {
    display: flex;
    justify-content: center;
    align-items: center;
    .main-slider__header {
      display: table-cell;
      vertical-align: middle;
      margin-top: 120px;
      padding: 0 30px;
    }
    .BackImg {
      margin-top: 50%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .FirstPage {
    .X,
    .I,
    .U {
      font-size: 45vw;
    }
    .uix-always-thinks {
      font-size: 5vw;
      right: 50px;
    }
    .U {
      left: 50px;
      top: 40%;
      transform: translateY(-50%);
    }
    .I {
      left: 50px;
      bottom: 20%;
      transform: translateY(-50%);
    }
    .X {
      bottom: 20%;
      right: 50px;
    }
    .BackImg {
      margin-top: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .FirstPage {
    .BackImg {
      img {
        width: 100%;
        height: auto;
      }
    }
    .contents {
      display: flex;
      gap: 8px;
      .text {
        img {
          width: 200px;
          max-width: 100%;
          height: auto;
        }
      }
      .desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 12px;
        text-decoration: underline;
        font-family: "poppins", sans-serif;
        margin-bottom: 8px;
        opacity: 0.6;
        font-weight: 500;
        span {
          line-height: 13px;
        }
      }
    }
  }
}
