.checkboxButton {
    font-family: Montserrat;
    font-size: 1.2vw;
    input[type="checkbox"] {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 99;
    }

    label {
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 1.35em;
        padding-left: 20px;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
        height: 100%;
        .requiredText {
            font-size: 1vw;
            color: #ff641f;
        }
    }
    .checkboxButton__wrapper {
        position: relative;
        display: flex;
        align-items: flex-start;
    }
    .checkboxButton__wrapper label {
        color: #ffffff;
        font-size: 24px;
    }

    .checkboxButton__wrapper .check {
        display: block;
        position: relative;
        border: 2px solid white;
        border-radius: 50%;

        min-height: 50px;
        min-width: 50px;
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        transition: border 0.25s linear;
        -webkit-transition: border 0.25s linear;
    }

    .checkboxButton__wrapper:hover .check{
        border-color: #ff641f;
    }
    .checkboxButton__wrapper:hover label{
        color:#ff641f
    }
    .checkboxButton__wrapper .check::before {
        display: block;
        position: absolute;
        content: "";
        border-radius: 100%;
        height: 12px;
        width: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
    }

    input[type="checkbox"]:checked ~ .check {
        border-color: #ff641f;
    }

    input[type="checkbox"]:checked ~ .check::before {
        background: #ff641f;
    }

    input[type="checkbox"]:checked ~ label {
        color: #ff641f;
    }
}

@media all and (max-width: 1200px) {
    .checkboxButton {
        font-size: 24px;
        .check {
            min-width: 4vw !important;
            min-height: 4vw !important;
            width: 4vw !important;
            height: 4vw !important;
            &::before {
                width: 1.5vw !important;
                height: 1.5vw !important;
            }
        }
        label{
            font-size: 2vw !important;
        }
        .requiredText {
            font-size: 14px;
        }
    }
}
@media all and (max-width: 400px) {
    .checkboxButton {
        .check {
            border: 1px solid white !important;
          
        }
        label{
            font-size: 1.5vw !important;
            padding-left: 5px;
        }
        .requiredText {
            font-size: 5px;
        }
    }
}
