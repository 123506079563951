   .footer {
       color: #a0a0a0;
       padding: 50px 15px;
       background-color: #F8F8F8;

       &-container {

           display: flex;
           justify-content: space-between;
           align-items: center;
           text-align: center;
           margin: 0 auto;
          max-width: 1280px;
          @media screen and (max-width: 767px) {
            flex-direction: column;
          }
       }

       &__logo {
           width: 74px;
           height: 34px;
       }

       &__icon {
           img {

               width: 18px;
           }

           height: 19px;
           display: flex;
           align-items: center;
           justify-content: start;
           color: #111;
           font-size: 12px;
           gap: 9px;
       }

       &__link {
           display: flex;
           align-items: start;
           flex-direction: column;
           gap: 8px;
       }

       &__address {
           font-size: 15px;
           font-weight: normal;
       }
   }