.AppWebcontainer {
  .app {
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    padding-top: 158px;
    .tit_bx {
      display: flex;
      .tit_img {
        margin-right: 50px;
      }
      .txt {
        .s_tit {
        }
        .s_txt {
          overflow: hidden;
          margin-top: 14px;
          color: #808080;
          font-size: 16px;
        }
      }
    }
    .graph {
      overflow: hidden;
      margin-top: 54px;
      padding-bottom: 4px;
      .graph_ul {
        list-style: none;
        .bar_wrap_bar1 {
          margin-top: 10px;
          font-size: 0;
          line-height: 0;
          .bar_area {
            display: inline-block;
            position: relative;
            .bar_skrollable {
              display: inline-block;
              position: relative;
              height: 30px;
              border: 2px solid #222;
              border-radius: 15px;
              vertical-align: top;
            }
            .color_skrollable {
              display: block;
              overflow: hidden;
              position: absolute;
              left: 4px;
              top: 4px;
              height: 30px;
              border-radius: 15px;
              background: #ffe346;
              z-index: -10;
            }
          }
        }
      }
    }
  }
}
