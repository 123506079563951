.ReauestImgBox {
    padding-top: 140px;

    .ReauestTopBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .ReauestText {
        font-family: Montserrat;
        color: #ffffff;
        font-size: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        padding-bottom: 160px;
    }
    .ReauestIngBox {
        max-width: 1630px;
        width: 100%;
        margin: auto;
    }
    .ReauestExplanationText {
        display: flex;
        font-family: Montserrat;
        color: #ffffff;
    }

    .FirstTextBox {
        margin-bottom: 42px;
        font-size: 24px;
        font-weight: 500;
    }
    .SecondTextBox {
        margin-bottom: 42px;
        font-size: 24px;
        font-weight: 500;
    }
    .ThirdTextBox {
        margin-bottom: 42px;
        font-size: 24px;
        font-weight: 500;
    }

    // .FirstButton{
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 195px;
    //     height: 57px;
    //     color: #222222;
    //     font-weight: 600;
    //     border: 1px solid #ff641f;
    //     background-color: #ff641f;
    //     padding-right: 5px;
    // }
    .Button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 57px;
        border: 1px solid #ffffff;
        padding-right: 5px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            background-color: #666666;
        }
        &:active {
            &:active {
                // content: url("./../../../src/_asset/images/mainback/black-download-icon.svg") !important;
            }
            background-color: #ff641f;
            color: #222;
            border-color: #ff641f;
            font-size: 16px;
            font-weight: 600;
        }
    }
    .BlackButton {
        padding-right: 10px;
    }
    .WhiteButton {
        padding-right: 10px;
    }
    .Web_Mobile {
        width: 100%;
    }
    .ThirdButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 195px;
        height: 57px;
        border: 1px solid #ffffff;
        padding-right: 5px;
    }
    .TextContainer {
        font-family: Montserrat;
        color: #ffffff;
        padding-top: 50px;
        font-size: 18px;
        line-height: 1.94;
        font-weight: 300;
        padding-bottom: 70px;
        span {
            font-weight: 500;
        }
    }
    .EmailContainer {
        font-family: Montserrat;
        color: #ffffff;
        font-size: 40px;
        line-height: 2.38;
        border-bottom: 2px solid #fff;
    }
}

@media all and (max-width: 1920px) {
    .ReauestImgBox {
        .ReauestTopBox {
            .ReauestText {
                font-size: 80px;
            }
            .TobContainer {
                .ReauestExplanationText {
                    justify-content: flex-end;
                    .FirstTextBox,
                    .SecondTextBox,
                    .ThirdTextBox {
                        font-size: 24px;
                    }
                    .Button {
                        width: 195px;
                        height: 57px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media all and (max-width: 1200px) {
    .ReauestImgBox {
        .ReauestTopBox {
            .ReauestText {
                font-size: 5vw;
            }
            .TobContainer {
                .ReauestExplanationText {
                    justify-content: flex-end;
                    .FirstTextBox,
                    .SecondTextBox,
                    .ThirdTextBox {
                        font-size: 1.5vw;
                    }
                    .Button {
                        width: 12vw;
                        height: 3vw;
                        font-size: 1vw;
                    }
                }
                .TextContainer {
                    font-size: 1.2vw !important;
                }
                .EmailContainer {
                    font-size: 2.8vw;
                }
            }
        }
    }
}
@media all and (max-width: 767px) {
    .ReauestImgBox {
        padding-top: 10vw;

        .ReauestTopBox {
            width: 100%;
        }
        .ReauestText {
            padding-right: 15px;
            font-family: Montserrat;
            color: #ffffff;
            font-weight: 500;
        }
        .ReauestIngBox {
            max-width: 1630px;
            width: 100%;
            margin: auto;
        }
        .ReauestExplanationText {
            width: 100%;
            max-width: 767px;
            font-family: Montserrat;
            color: #ffffff;
        }
        .CompanyLetter,
        .Web_Mobile,
        .Branding {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .Branding {
            padding-right: 0;
        }

        .FirstTextBox {
            margin-bottom: 12px;
            font-size: 12px;
            font-weight: 500;
        }
        .SecondTextBox {
            margin-bottom: 12px;
            font-size: 12px;
            font-weight: 500;
        }
        .ThirdTextBox {
            margin-bottom: 12px;
            font-size: 12px;
            font-weight: 500;
        }
        .Button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 37px;
            border: 1px solid #ffffff;
            padding-right: 5px;
            font-size: 10px;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                background-color: #666666;
            }
            &:active {
                background-color: #ff641f;
                color: #222;
                font-size: 10px;
                font-weight: 600;
            }
        }
        .BlackButton {
            padding-right: 10px;
        }
        .WhiteButton {
            width: 10px;
            height: 10px;
            padding-right: 5px;
        }

        .TobContainer {
            width: 100%;
        }
        .ThirdButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 195px;
            height: 57px;
            border: 1px solid #ffffff;
            padding-right: 5px;
        }
        .TextContainer {
            padding-top: 5vw;
            padding-bottom: 6vw;
        }
        .EmailContainer {
            font-family: Montserrat;
            color: #ffffff;
            font-size: 2.5vw;
            line-height: 2.38;
            border-bottom: 1px solid #fff;
        }
    }
}
@media all and (max-width: 500px) {
    .ReauestImgBox {
        .ReauestTopBox {
            flex-direction: column;
        }
        .ReauestText {
            padding-right: 10px;
            padding-bottom: 20px;
        }

        .Button {
            width: 50px !important;
            height: 15px !important;
            font-size: 4.5px !important;
            img {
                width: 8px;
                height: 8px;
            }
        }
        .BlackButton {
            padding-right: 10px;
        }
    }
}
@media all and (max-width: 300px) {
    .ReauestImgBox {
        .ReauestTopBox {
            flex-direction: column;
        }
        .Button {
            padding-right: 5px;
            img {
                width: 6px;
                height: 6px;
            }
        }
    }
}
