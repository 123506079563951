.Econceptcontainer {
  background-image: url(../../../../../_asset/images/emart/bg_conception.png);
  background-size: cover;
  overflow: hidden;
  height: 1198px;
  margin-top: 400px;
  .litle_title {
    overflow: hidden;
    margin: 147px 0 0 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .title {
    margin: 379px 0 0 18px;
    font-size: 90px;
    font-weight: 600;
  }
  .text {
    overflow: hidden;
    margin: 69px 0 0 10px;
    color: #222;
    font-size: 22px;
    line-height: 60px;
  }
}
