.Ptypography_container {
  overflow: hidden;
  .Ptypo_cont_1 {
    overflow: hidden;
    height: 250px;
    background: #fff;
    .Ptypo_txt_bx {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 80px;
      .Ptypo_tit {
        font-size: 13px;
        font-weight: 500;
      }
      .Ptypo_txt {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
  }
  .Ptypo_cont_2 {
    overflow: hidden;
    height: 800px;
    background: #c3c8d0;
    .Ptypo_image_Wrap {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 153px;
      > img {
        width: 100%;
      }
    }
  }
  .Ptypo_cont_3 {
    overflow: hidden;
    height: 800px;
    background: url(../../../../../_asset/images/Prorenata/img_prn19.jpg) center
      top no-repeat;
    background-size: cover;
    .Ptypo_image_Wrap_2 {
      overflow: hidden;
      width: 960px;
      margin: 0 auto;
      padding-top: 288px;
      > img {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Ptypography_container {
    .Ptypo_cont_1 {
      width: 100%;
      height: fit-content;
      padding-bottom: 20px;
      .Ptypo_txt_bx {
        width: 100%;
        padding: 30px 10px;
      }
    }
    .Ptypo_cont_2 {
      width: 100%;
      height: fit-content;
      .Ptypo_image_Wrap {
        width: 100%;
        padding-bottom: 153px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .Ptypo_cont_3 {
      height: 300px;
      .Ptypo_image_Wrap_2 {
        width: 100%;
        padding-top: 110px;
      }
    }
  }
}
