 .section4 {
     --heightSection4: 900px;
     --fsTit: 36px;
     --fsDetail: 22px;
     height: var(--heightSection4);
     display: flex;
     justify-content: center;
     flex-direction: column;
     background-color: #f5f8fc;

     &-container {
         margin: 0 auto;
         width: 1280px;
         padding: 0 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: row;
         gap: 40px;

         .left {
             width: 50%;

             .title {
                 font-size: 50.5px;
                 color: #00d794;
                 font-weight: bold;
             }

             .item {
                 padding-top: 40px;

                 &-title {
                     font-weight: bold;
                     font-size: var(--fsTit);
                 }

                 &-subtitle {
                     font-size: var(--fsDetail);
                     padding-top: 12px;
                     color: #16abfc;
                     font-weight: bold;
                 }

                 &-detail {
                     font-size: var(--fsDetail);
                     padding-top: 12px;
                     color: #333333;
                     line-height: 1.4;
                 }
             }
         }

         .right {
             display: flex;
             align-items: center;
             flex-direction: column;
             width: 50%;

             img {
                 max-width: 579px;
             }
         }

         @media screen and (max-width: 1279px) {
             width: 100%;

             .right {
                 img {
                     max-width: 400px;
                 }
             }
         }

         @media screen and (max-width: 800px) {
             flex-direction: column;

             .left,
             .right {
                 width: 100%;
             }
         }

         @media screen and (max-width: 500px) {
             .right {
                 img {
                     max-width: 80%;
                 }
             }
         }
     }

     @media screen and (max-width: 1000px) {
         --fsTit: 30px;
         --fsDetail: 20px;
     }

     @media screen and (max-width: 500px) {
         --fsTit: 24px;
         --fsDetail: 16px;
     }

 }