 .section6 {
     --width: 1280px;
     --heightSection6: 900px;
     height: var(--heightSection6);
     display: flex;
     justify-content: center;
     flex-direction: column;
     background-color: #f5f8fc;

     &-container {
         margin: 0 auto;
         width: var(--width);
         padding: 0 20px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;


         .title {
             font-size: 50px;
             font-weight: bold;

             @media screen and (max-width: 767px) {
                 font-size: 32px;

             }
         }

         .detail {
             font-size: 22px;
             padding-top: 25px;
         }

         .slides {
             padding-top: 100px;
             width: 100%;

             .slide {
                 display: flex;
                 flex-direction: column;
                 align-items: center;
                 justify-content: center;

                 img {
                     max-width: 688px;

                     @media screen and (max-width: 767px) {
                         max-width: 100%;

                     }
                 }

             }
         }

         .next {
             position: absolute;
             top: 50%;
             display: block;
             transform: translate(0, -50%);
             cursor: pointer;
             right: 0;

             img {
                 width: 22px;

                 @media screen and (max-width: 767px) {
                     width: 0px;

                 }
             }
         }

         .prev {
             position: absolute;
             top: 50%;
             display: block;
             transform: translate(0, -50%);
             cursor: pointer;

             img {
                 width: 22px;

                 @media screen and (max-width: 767px) {
                     width: 0px;

                 }
             }
         }
     }

     @media screen and (max-width: 1279px) {
         --width: 90%;
     }

     @media screen and (max-width: 767px) {
         --width: 90%;
         --heightSection6: 500px;
     }

 }