.serverkit_session9 {
    color: white;
    background-color: #dcdcdc;
    padding: 74px 0 58px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        padding: 0 17.4%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      
        .sk_middle{
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: center;
            color: #a0a0a0;
            display: flex;
            flex-direction: column;
            gap:5px;
        }
        .sk_social{
            display: flex;
            flex-direction: column;
            gap:15px;
            .sk_item{
                display: flex;
                gap:9px;
                align-items: center;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #111;
            }
        }

        @media screen and (max-width:1400px){
           flex-direction: column; 
        }
    }

    @media screen and (max-width:768px) {
        padding: 34px 0 28px 0;
        .sk_main{
            gap: 20px;
        }
    }
}
