.serverkit_session4 {
    color: white;
    background-color: #f8f6ff;
    padding: 147px 0 93px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #f8f6ff;
        gap:213px;
      
        .sk_left{
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: left;
            color: #111;

        }
        .sk_right{
            display: flex;
            flex-direction: column;
            gap:95px;

            .sk_top{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 90%;
                    height: 90%;
                    object-fit: cover;
                }
            }
            .sk_bottom{
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.28;
                letter-spacing: normal;
                text-align: center;
                color: #111;
                span{
                    color: #0142ff;
                }
            }


        }


        @media screen and (max-width: 1400px){
           flex-direction: column; 
           align-items: center;
        }
    }

    @media screen and (max-width:768px){
        padding: 80px 0 40px 0;

        .sk_main{
            gap:80px;
            .sk_left{
                font-size: 25px;
            }

            .sk_right{
                padding: 0 10px 0 10px;
                .sk_bottom{
                    font-size: 18px;
                }
            }
        }
    }


}
