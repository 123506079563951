a,
a:active,
a:focus,
a:hover,
button {
  text-decoration: none;
  outline: none;
}

a {
  color: rgb(255, 255, 255);
}
.FooterNavContainer{
        user-select: none;
        left: 0;
        bottom: 80px;
        opacity: 1;
        transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s, margin 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

        .sessions {
            margin-bottom: 10px;
            font-size: 11px;
            font-weight: 700;
            opacity: 0.65;
        }
      
        .sessions>span {
        display: inline-block;
        padding-right: 21px;
        position: relative;
      }
      
        .sessions>span::after {
        content: "";
        position: absolute;
        right: 9px;
        top: 2px;
        display: block;
        width: 1px;
        height: 9px;
        background-color: rgba(0, 0, 0, 0.4);
      }
      
      .sessions>span:last-child::after {
        display: none;
      }
      
      .links {
        margin-bottom: 8px;
        display: flex;
        list-style: none;
        padding-left: 0;
      }
      
      .links_item {
        position: relative;
        padding-right: 21px;
        font-size: 14px;
        font-weight: 700;
      }
      
      .links_item::after {
        content: "";
        position: absolute;
        right: 9px;
        top: 4px;
        display: block;
        width: 1px;
        height: 11px;
        background: rgba(0, 0, 0, 0.2);
      }
      
      .links_item a {
        position: relative;
        display: inline-block;
      }
      .links_item:last-child {
        padding: 0px;
      }
      
      .links_item:last-child::after {
        display: none;
      }

    // .linksBox .copyright {
    //     transition: opacity 0.3s ease 0s;
    //     opacity: 0;
    //     font-size: 3.5vw;
    //     margin-top: 9%;
    // }
    .copyright {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      color:rgb(255, 255, 255);
    }

    .hv-underline {
      position: relative;
      display: inline-block;
      line-height: 1em;
    }
    
    .hv-underline::after {
      content: "";
      transition: width 0.2s ease 0s, left 0.2s ease 0s, transform 0.2s ease 0s;
      width: 0px;
      height: calc(1px + 0.1em);
      background-color: rgb(255, 255, 255);
      position: absolute;
      left: 50%;
      bottom: -0.15em;
    }
    
    .hv-underline:hover::after,
    .underline {
      width: 100%;
      left: 0px;
    }
    .linksBox{
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
    }

}
@media (max-width:1270px) {
  .FooterNavContainer{
    .links_item {
      font-size: 13px;
    }
    .copyright {
      font-size: 13px;
    }
  }
}

//Tablet | Mobile 분기점
@media all and (max-width:767px) {
  .FooterNavContainer{
    .links_item {
      font-size: 12px;
      padding-right: 13px;
    }
    .copyright {
      font-size: 12px;
    }
    .links_item::after {
      right: 6px;
      top: 3px;
    }
  }
}

@media all and (max-width:330px) {
  .FooterNavContainer{
    .links_item {
      font-size: 10px;
      padding-right: 10px;
    }
    .copyright {
      font-size: 10px;
    }
    .links_item::after {
      right: 4px;
      top: 2px;
    }
  }
}