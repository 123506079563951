.socialcontainer {
  overflow: hidden;
  background: #000;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  .social_top {
    max-width: 1226px;
    max-width: 100%;
    margin: 253px auto 0;
    @media screen and (max-width: 480px) {
      margin-top: 50px;
      > div:nth-child(1) {
        font-size: 30px;
        letter-spacing: 20px;
      }
      > div:last-child {
        margin-top: 30px;
      }
    }
    .list {
      display: inline-block;
      margin-right: 39px;
      vertical-align: top;
      > img {
        width: 320px;
        height: 693px;
      }
    }
  }
}
