.PbgPageTwo_container {
  overflow: hidden;
  height: 1000px;
  background: #edeff2 url(../../../../../_asset/images/Prorenata/img_prn10.jpg)
    center top no-repeat;

  @media screen and (max-width: 767px) {
    background: #edeff2
      url(../../../../../_asset/images/Prorenata/img_prn10.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    max-height: 300px;
  }
}
