.Pdiffer_container {
  overflow: hidden;
  height: 800px;
  background: #fff;
  .Pdiffer_cont {
    overflow: hidden;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .Pdiffer_txt_box {
      margin-top: 100px;
      .tit {
        font-size: 14px;
        font-weight: 500;
      }
      .txt {
        font-size: 13px;
        padding-top: 20px;
        line-height: 1.7;
        font-weight: 500;
      }
    }
    .differ_image {
      overflow: hidden;
      margin: 136px 0 0 107px;
      width: 575px;
      height: 576px;
      > img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .Pdiffer_container {
    padding: 0 10px;
    height: fit-content;
    .Pdiffer_cont {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > div:nth-child(2) {
        margin: 136px 0 0 0;
        max-width: 575px;
        max-height: 576px;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
