.Eeventcontainer {
  position: relative;
  width: 1200px;
  margin: 509px auto 0;

  .sub_text {
    overflow: hidden;
    margin: 33px 0 0 2px;
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
  }

  .event_screen {
    margin: 0 0 0 22px;
    .list_box {
      display: flex;
      list-style: none;

      .event_Wrap {
        > img {
          width: 350px;
          height: 720px;
          border-radius: 40px;
          display: block;
          overflow: hidden;
          margin-bottom: 59px;
          box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
        }
      }

      .event_Wrap02 {
        > img {
          display: block;
          overflow: hidden;
          margin-bottom: 59px;
          box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
        }
      }
      > img {
        box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
      }
    }
  }
}
