.overviewcontainer {
  overflow: hidden;
  position: relative;
  margin: 243px auto 0;
  text-align: center;
  @media screen and (max-width: 480px) {
    margin-top: 50px;
  }
  .overview_top {
    position: absolute;
    right: 50%;
    margin-right: 215px;
    display: flex;
    max-width: 375px;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      left: 0;
      > :nth-child(1) {
        margin-left: 12px;
      }
      > :nth-child(2) {
        margin-right: 12px;
      }
    }
    @media screen and (min-width: 481px) and (max-width: 978px) {
      left: 0;
      > :nth-child(1) {
        margin-left: 8%;
      }
    }
  }
  .mv-wrap {
    display: inline-block;
    max-width: 820px;
    max-height: 820px;
    width: 100%;
    height: 100%;
    margin: 150px 0 130px;
    text-align: center;
    @media screen and (max-width: 480px) {
      margin: 50px 0;
      #overflow_video {
        width: 250px;
      }
    }
    @media screen and (min-width: 481px) and (max-width: 978px) {
      #overflow_video {
        width: 500px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .fnJWgQ {
      font-size: 30px;
    }
    .gwzuBG {
      font-size: 12px;
      margin-bottom: 150px;
    }
  }
  .strong_text {
    font-size: 55px;
    font-weight: 600;
    line-height: 1.5;
  }
}
