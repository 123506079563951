.serverkit_session3 {
    color: white;
    background-color: #fff;
    padding: 120px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .sk_top {
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: center;
            color: #111;
            margin-bottom: 80px;
        }

        .sk_bottom {
            margin-top: 130px;
            font-size: 36px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #111;
            span {
                color: #6d42ff;
            }
        }

        .sk_img{
            width: 80%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
                          @media screen and (max-width: 767px) {
                            width: 100%;
                          }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 60px 0 100px 0;

        .sk_main{
            gap: 50px;
           
            .sk_top{
                font-size: 25px;
                margin-bottom: 30px;
            }
            .sk_bottom{
                font-size: 16px;
                width: 100%;
                margin-top: 50px;

            }
        }
        
    }
}
