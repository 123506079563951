.videocontainer {
  .video_top {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .video_wrap {
      position: relative;
      left: 50%;
      margin-top: -40px;
      transform: translateX(-50%);
      > video {
        width: 100%;
      }
      @media screen and (max-width: 480px) {
        margin-top: -20px;
      }
      .img_wrap {
        .video_img01 {
          position: absolute;
          top: 40%;
          right: 50%;
          margin-right: 500px;
          > img {
            width: 236px;
            height: 329px;
          }
        }
        .video_img02 {
          position: absolute;
          top: 15%;
          left: 70%;
          > img {
            width: 375px;

            height: 812px;
          }
        }
      }
    }
  }
}
