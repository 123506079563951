.serverkit_session5 {
    color: white;
    background-color: #fff;
    padding: 165px 0 170px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap:213px;
      
        .sk_left{
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: right;
            color: #111;
            transform: translateY(-60px);

        }
        .sk_right{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap:70px;
            .sk_bottom{
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.28;
                letter-spacing: normal;
                text-align: center;
                color: #111;
                span{
                    color: #6d42ff;
                }
            }
        }

        @media screen and (max-width: 1400px){
           flex-direction: column; 

           .sk_top{
            width: 90%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
           }
        }
    }

    @media screen and (max-width:768px){
        padding: 80px 0 60px 0;
        .sk_main{
            gap: 70px;

            .sk_left{
                transform: unset;
                font-size: 25px;
                text-align: center;

            }

            .sk_right{
                gap: 30px;
                .sk_bottom{
                    font-size: 18px;
                }
            }
        }
    }
}
