.serverkit_session7 {
    color: white;
    background-color: #f8f6ff;
    padding: 120px 0 157px 0;
    display: flex;
    align-items: center;
    width: 100%;
    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .sk_title {
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            margin-bottom: 22px;
            text-align: left;
            color: #111;
            align-self: flex-start;
            padding-left: 17.4%;
        }
        .sk_des{
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            margin-bottom: 60px;
        }


        @media screen and (max-width:1400px){
            .sk_image{
             width: 100%;
             img{
                 width: 100%;
                 height: 100%;
                 object-fit: cover;
             }
            } 
         }
    }

    @media screen and (max-width:768px){
        padding: 60px 0 50px 0;

        .sk_main{
            width: 90%;
            margin: 0 auto;
            .sk_title{
                padding-left: unset;
                font-size: 25px;
                text-align: center;
                align-self: center;
            }
        }

    }
}
