.Emart_container {
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    .Emartcover {
      padding: 0 10px;
      padding-top: 150px;
      .con_bx {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        .tit_bx {
          padding: 0;
          width: 100%;
        }
        .Ecover_Img {
          .Ecover_Wrap {
            max-width: 305px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .Eoverflowcontainer {
      padding: 0 10px;
      padding-top: 150px;
      height: fit-content;
      .con_bx {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .sc-JrDLc {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
      }
    }
    .Einterviewcontainer {
      padding: 0 10px;
      padding-top: 100px;
      .con_box {
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .sc-fjvvzt {
          max-width: 584px;
          width: 100%;
          .Einter_title {
            font-size: 30px;
          }
          .Einter_text {
            font-size: 14px;
          }
        }
        .con_txt {
          width: 240px;
          height: 355px;
          margin: 0 auto;
          margin-top: 50px;
          background-size: 240px 355px;
          margin-right: 10px;
          float: none;
          .text1,
          .text2,
          .text3,
          .text4 {
            display: none;
          }
        }
      }
    }
    .AppWebcontainer {
      padding: 0 10px;
      .app {
        max-width: 1200px;
        width: 100%;
        .tit_bx {
          display: flex;
        }
      }
    }
    .Edatacontainer {
      padding: 0 10px;
      .inner {
        max-width: 1200px;
        width: 100%;
        margin: 100px auto 0;
        .graph {
          margin-top: 0px;
          .graph_bx {
            width: 487px;
            height: 209px;
            background-size: 487px 209px;
          }
        }
      }
    }
    .Edirectioncontainer {
      padding: 0 10px;
      max-width: 1200px;
      width: 100%;
      .txt_box {
        width: 94%;
        .screen_bx {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > div {
            margin-right: 20px;
          }
          > div:first-child {
            margin-top: 50px;
          }
          .image_Wrap {
            margin-bottom: 50px;
          }
          .ghKATD {
            margin-top: 50px;
          }
        }
        .sc-cfxfcM > img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
        }
        .sc-cfxfcM > ul > .txt_area:nth-child(4),
        .txt_area:nth-child(5) {
          margin-top: 0;
        }
      }
    }
    .Econceptcontainer {
      height: fit-content;
      > div {
        width: 100%;
        .title {
          margin-top: 100px;
          font-size: 70px;
        }
        .text {
          font-size: 16px;
          text-align: left;
          margin-right: 5px;
        }
      }
    }
    .Elayoutcontainer {
      max-width: 1200px;
      width: calc(100% - 20px);
      margin-top: 200px;
      .sc-hRJfrW {
        .layout_01 > img {
          width: 230px;
          height: 450px;
          max-width: 100%;
          max-height: 100%;
          background-size: cover;
        }
        .layout_02 > img {
          width: 230px;
          height: 450px;
          max-width: 100%;
          max-height: 100%;
          background-size: cover;
        }
      }
      > div:last-child {
        > ul {
          display: flex;
          flex-direction: column;
          > li > img {
            width: 230px;
            height: 450px;
            max-width: 100%;
            max-height: 100%;
            background-size: cover;
          }
          > li:nth-child(2) > img {
            height: 700px;
          }
        }
      }
    }
    .Esplashcontainer {
      padding: 150px 10px 40px;
      height: fit-content;

      .con_bx {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        > ul {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          > div {
            margin-top: 50px;
            img {
              width: 230px;
              height: 450px;
              max-width: 100%;
              max-height: 100%;
              background-size: cover;
            }
          }
        }
      }
    }
    .Equickcontainer {
      width: calc(100% - 10px);
      > div:last-child {
        overflow-x: scroll;
        scrollbar-width: thin;
      }
    }
    .Ememocontainer {
      margin-top: 150px;
      padding: 50px 10px;
      height: fit-content;
      > div:last-child > img {
        max-width: 448px;
        width: 100%;
        max-height: 928px;
        height: 100%;
      }
    }
    .Ecataloguecontainer {
      margin-top: 150px;
      padding: 0 10px;
      .con_card {
        position: relative;
        overflow: hidden;
        max-width: 1200px;
        width: 100%;
        height: 900px;
        .tit_bx {
          margin-left: 0;
          > div > div {
            font-size: 12px;
          }
          .bg {
            top: 410px;
            height: 395px;
          }
        }
        .card {
          top: 380px;
          > div:first-child {
            margin-top: 80px;
            margin-right: 40px;
          }
          img {
            max-width: 150px;
            max-height: 175px;
            width: 100%;
            height: 100%;
          }
          > div:last-child > div {
            margin-bottom: 30px;
          }
        }
        .txt {
          display: none;
        }
      }
    }
    .Eeventcontainer {
      margin-top: 50px;
      width: calc(100% - 10px);
      > div:last-child > ul {
        padding: 0;
        flex-direction: column;
        .sc-brPLxw {
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .sc-iMWBiJ {
          .event_Wrap,
          .sc-bBeLUv {
            img {
              width: 115%;
              height: 100%;
            }
          }
        }
        .sc-fvtFIe {
          .event_Wrap02 {
            img {
              width: 93%;
              height: 100%;
            }
          }
          .sc-ihgnxF {
            img {
              width: 93%;
              height: 100%;
            }
          }
        }
      }
    }
    .Ecouponcontainer {
      padding: 0 10px;
      padding-top: 150px;
      .con_bx {
        max-width: 1200px;
        width: 100%;
        .list {
          display: flex;
          flex-direction: column;
          .coupon_list01 > .coupon_Wrap {
            margin: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          > .sc-jaXxmE > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .Eelementcontainer {
      margin-top: 150px;
      .con_bx {
        width: calc(100% - 10px);
      }
    }
    .Enamecontainer {
      > div:first-child {
        max-width: 1200px;
        width: 100%;
      }
      .name_bg {
        height: 300px;
      }
    }
  }
}
