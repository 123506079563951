.covercontainer {
  background-image: url(../../../../../_asset/images/29TV/bg_cover.jpg);
  background-position: 0% 50%;
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;

  .cover_textbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    > h1 {
      margin: unset;
      overflow: hidden;
      width: 1194px;
      height: 250px;
      background: url(../../../../../_asset/images/29TV/tit_cover.png) 0 0
        no-repeat;
      background-size: 1194px 250px;
      text-align: center;
      text-indent: -1000em;
      @media screen and (max-width: 480px) {
        width: 200px;
        height: 50px;
        max-width: 100%;
        max-height: 100%;
        background-size: 200px 40px !important;
      }
      @media screen and (min-width: 480px) and (max-width: 978px) {
        width: 350px;
        height: 130px;
        max-width: 100%;
        max-height: 100%;
        background-size: 350px 70px !important;
      }
    }
    .mv_Wrap {
      position: absolute;
      top: 30px;
      left: 455px;
      width: 500px;
      height: 160px;
    }
    .desc {
      color: #fff;
      font-size: 23px;
      line-height: 1.5;
      margin: unset;
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
  }
  .copy01 {
    position: absolute;
    top: 50%;
    left: 3.1vw;
    margin-top: 50px;
    color: #fff;
    font-size: 20px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      top: 36%;
    }
    @media screen and (min-width: 481px) and (max-width: 978px) {
      top: 36%;
    }
  }
  .copy02 {
    position: absolute;
    bottom: 3.6vw;
    left: 3.1vw;
    color: #fff;
    font-size: 17px;
    letter-spacing: 2px;
    @media screen and (max-width: 480px) {
      font-size: 11px;
    }
  }
  .copy03 {
    position: absolute;
    bottom: 4.8vw;
    right: 50%;
    margin-right: 410px;
    color: #fff;
    font-size: 17px;
    @media screen and (max-width: 480px) {
      font-size: 11px;
      bottom: 5.8%;
      left: 20%;
      margin: 0;
      width: 100%;
    }
    @media screen and (min-width: 481px) and (max-width: 978px) {
      bottom: 50px;
      left: 150px;
      min-width: 100%;
    }
  }
}
