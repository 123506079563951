.serverkit_session2 {
    color: white;
    background-color: #f8f6ff;
    padding: 120px 0 172px 0;
    display: flex;
    align-items: center;
    width: 100%;

    .sk_main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 130px;

        .sk_top {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;

            .sk_title {
                font-size: 50px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #111;
            }
            .sk_content {
                font-size: 26px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #111;
            }
        }

        .sk_bottom {
            display: flex;
            justify-content: center;
            gap: 45px;

            @media screen and (max-width: 1400px) {
                flex-direction: column;
                align-items: center;

                .sk_item {
                    width: 90%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 60px 0 100px 0;

        .sk_main{
            gap: 50px;
            .sk_top{

            .sk_title{
                font-size: 25px;
            }
            .sk_content{
                font-size: 16px;
                width: 90%;
            }
            }
        }
        
    }
}
