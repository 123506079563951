.Esplashcontainer {
  height: 1426px;
  margin-top: 469px;
  padding-top: 400px;
  background-image: url(../../../../../_asset/images/emart/bg_splash.png);
  background-size: cover;
  .con_bx {
    width: 1200px;
    margin: 0 auto;
    .con_title {
      font-size: 40px;
      font-weight: 600;
    }
    .con_text {
      overflow: hidden;
      margin-top: 35px;
      color: #222;
      font-size: 16px;
      font-weight: 500;
      line-height: 38px;
    }
    .screen {
      display: flex;
      margin-top: 150px;
      font-size: 0;
    }
  }
}
