.HeaderMainContainer {
  padding: 30px 50px 0px;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  .HeaderMain {
    max-width: 1580px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    z-index: 1;
  }
}
@media all and (max-width: 1920px) {
  .HeaderMainContainer {
    padding: 30px 50px 0px;
  }
}
//Tablet 분기점
@media all and (max-width: 1024px) {
}
//Mobile 분기점
@media all and (max-width: 767px) {
}
@media all and (max-width: 500px) {
  .HeaderMainContainer {
    .HeaderMain {
      .HeaderLogoMain {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .ButtonContainer {
        .btn {
          font-size: 10px;
          align-items: flex-end;
        }
      }
    }
  }
}

@media all and (max-width: 400px) {
  .HeaderMainContainer {
    padding: 30px 10px 0;
  }
}
