.PbgPageFour_container {
  overflow: hidden;
  height: 800px;
  background: #000;

  .PbgPage_cont_1 {
    overflow: hidden;
    float: left;
    width: 50%;
    height: 100%;
    background: #000;
    text-align: center;
    .Left_cont {
      overflow: hidden;
      margin-top: 255px;
    }
  }
  .PbgPage_cont_2 {
    overflow: hidden;
    float: left;
    width: 50%;
    height: 100%;
    background: url(../../../../../_asset/images/Prorenata/img_prn15.jpg) center
      center repeat;
    text-align: center;
    .Right_cont {
      overflow: hidden;
      margin-top: 255px;
    }
  }
}

@media screen and (max-width: 767px) {
  .PbgPageFour_container {
    height: fit-content;
    .PbgPage_cont_1 {
      .Left_cont {
        width: 100%;
        height: 100%;
        margin: 30px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .PbgPage_cont_2 {
      .Right_cont {
        width: 100%;
        height: 100%;
        margin: 30px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
