.Pdesign_container {
  overflow: hidden;
  height: 996px;
  background: #f1f2f4;
  .Pdesign_cont {
    overflow: hidden;
    position: relative;
    width: 960px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    .Pdesign_txt_bx {
      overflow: hidden;
      float: left;
      margin-top: 93px;
      .Pdesign_tit {
        font-size: 14px;
        font-weight: 500;
      }
      .Pdesign_txt {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;
      }
    }
    .Pdesign_image_Wrap {
      overflow: hidden;
      float: left;
      margin: 131px 0 0 108px;
      width: 576px;
      height: 710px;
      > img {
        width: 100%;
      }
    }
  }
}
